@import '../../../assets/styles/setting';
body{
    .user-menu-list{
        .MuiPopover-paper{
            top: 46px !important;
            ul{
                li{
                    width: 140px;
                }
            }
        }
    }
    .primary-header{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background: #fff;
        z-index: 999;
        padding: 2px 10px;
        border-bottom: 1px solid #d7d7d7 !important;
        .app-logo{
            img{
                width: 32px;
            }
        }
        .app-other-nav{
            display: flex;
            float: right;
            align-items: center;
            .header-right-item{
                margin-left: 10px;
                border-left: 1px solid #d7d7d7;
                padding-left: 10px;
                &:first-child{
                    margin-left: 0;
                    padding-left: 0;
                    border-left: none;
                }
            }
            .short-icons{
                margin-right: 5px;
                ul{
                    display: flex !important;
                    align-items: center !important;
                    justify-content: center !important;
                    li{
                        cursor: pointer;
                        margin-left: 8px;
                        padding-left: 8px;
                        &:first-child{
                            margin-left: 0;
                            padding-left: 0;
                        }
                    }
                }
            }
            .autocomplete-width{
                .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
                     padding: 0px;
                }
                width: 240px;
            }
            .user-details{
                display: flex;
                align-content: center;
                cursor: pointer;
                .MuiList-root{
                    top: 45px !important;
                }
                .MuiAvatar-root{
                    margin-right: 5px;
                }
                h5{
                    @include elementStyles($size: $font15, $color:#000, $fontFamily: $ibmplexMedium, $weight:500);
                }
                p{
                    @include elementStyles($size: $font11, $color:#000, $fontFamily: $ibmplexRegular, $weight:400);
                }
            }
        }
    }
    .primary-side-nav{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 3;
        transition: all .5s ease;
        width: 80px;
        background: #6b5eb0;
        transition: all .5s cubic-bezier(.075,.82,.165,1);
        padding-top: 60px;
        .menu-link{
            text-align: center;
            a{
                display: block;
                padding: 8px 3px;
                img{
                    margin-bottom: 4px;
                }
                p{
                    @include elementStyles($size: $font11, $color:#fff, $fontFamily: $ibmplexRegular, $weight:400);
                }
                &.active{
                    background: rgba(0, 0, 0, .2);
                }
            }
        }
    }
    .report-issue-modal{
        .MuiInputBase-multiline{
            padding: 5px !important;
        }
        .MuiFormControl-marginNormal{
            margin-bottom: 0;
        }
        .MuiOutlinedInput-input{
            padding: 14px !important;
            @include elementStyles($size: 15px, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
            &.Mui-disabled{
                cursor: not-allowed;
              }       
        }
        .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
            padding: 0;
            &.Mui-disabled{
                cursor: not-allowed;
              }
        }
        .MuiTextField-root {
            label{
                @include elementStyles($size: 14px, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
            }
        }
        .MuiDialogTitle-root{
            padding: 10px 24px;
            h2{
                @include elementStyles($size: 16px, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
            }
        }
        .MuiDialogContent-root{
            padding: 10px 24px;
        }
        .MuiDialogActions-root{
            padding: 10px 24px;
        }
    }
    .whats-new-container {
        width: 97.5%;
        margin-left: 56px;
        background-color: white;
        min-height: 93vh;

        .whats-new-header {
            position: fixed;
            width: 94vw;
            background-color: white;
            border-bottom: 1px solid #cccccc;
            font-size: 20px;
            padding: 16px 15px;
            color: #013e54;
        }

        .whats-new-body {
            padding: 82px 20px 20px 20px;

            .feature-card {
                margin-bottom: 20px;
                padding: 10px;

                .feature-date {
                    margin: 0px !important;
                }

                p {
                    margin-bottom: 2px;
                    font-size: 13px;
                }

                .feature-title-wn {
                    color: #0743b2;
                    margin: 20px 0px;
                }
            }
        }

        .faq-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: 10;
            position: fixed;
            width: 94vw;
            background-color: white;
            border-bottom: 1px solid #cccccc;
            font-size: 20px;
            padding: 0px 15px;
            color: #013e54;

            .MuiFormControl-marginNormal {
                width: 40%;
                margin-bottom: 8px !important;
                margin-top: 15px !important;
            }
        }

        .notification-header {
            padding: 16px 15px !important;
        }

        .MuiOutlinedInput-input {
            padding: 12px 14px;
        }

        .faq-body {
            padding: 64px 20px 20px 20px;

            .faq-car-title {
                color: #0743b2;
                margin: 25px 0px 10px 0px;
            }

            .faq-answer-section {
                font-size: 13px;

                ul {
                    margin-left: 14px !important;
                }
            }
        }

        .notifications-body {
            padding: 82px 20px 10px 20px;

            .read {
                color: #969595;
            }

            .unread {
                font-weight: 700;
            }

            .read-more-link {
                color: #2f8cff !important;
                font-family: Conv_IBMPlexSans-Medium;
                font-size: 12px;
                cursor: pointer;
            }

            .mark-all-read {
                text-align: right;
                color: #2f8cff !important;
                font-family: Conv_IBMPlexSans-Medium;
                font-size: 13px;
                margin-bottom: 5px;
                cursor: pointer;
            }
        }
    }
    @media (min-width: 992px) and (max-width:1200px) {
        header{
            display: block !important;
        }
    }
}