
@import '/setting';

body {
.project-forms-section-t007 {
  margin-top: 58px !important;
  margin-left: 40px !important;

  .form-wrapper-head {
    padding: 5px 15px 5px 10px;
    background: #fff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 45px;
    left: 70px;
    right: 0;
    z-index: 11;

    .project-detail {
      border-right: 1px solid #d7d7d7;
      padding-right: 10px;

      .project-percentage {
        // float: left;
        padding: 0 5px 0 0;
      }

      .project-card {
        h3 {
          margin-bottom: 3px;

          span {
            @include elementStyles($size: 16px, $color: $black, $fontFamily: $ibmplexBold);
            cursor: pointer;
          }

          text-decoration: underline;
          line-height: 18px;
        }

        p {
          .project-status {
            margin-right: 5px;
            display: inline-block;

            .cancelled {
              background: #F5C7CA;
              color: #5e2135;
              padding: 3px 6px;
              border-radius: 4px;
            }

            .draft {
              background: #EBD186;
              color: #7F5E0D;
              border-radius: 4px;
              padding: 3px 6px;
              border-radius: 4px;
            }

            .initiated {
              background: #acacac;
              color: #000000;
              padding: 3px 6px;
              border-radius: 4px;
            }

            .launched {
              background: #7b8bff;
              color: #fff;
              padding: 3px 6px;
              border-radius: 4px;
            }

            .in-review {
              background: #93ec0d;
              color: #ffffff;
              padding: 3px 6px;
              border-radius: 4px;
            }

            .completed {
              background: #93c49f;
              color: #264D1C;
              padding: 3px 6px;
              border-radius: 4px;
            }

            .approved {
              background: #63eac9;
              color: #264D1C;
              padding: 3px 6px;
              border-radius: 4px;
            }

            .archived {
              background: #9a9991;
              color: #264D1C;
              padding: 3px 6px;
              border-radius: 4px;
            }
          }

          .project-dates {
            @include elementStyles($size: 9px, $color: $black, $fontFamily: $ibmplexMedium);
            opacity: .9;
          }
        }
      }
    }

    .asset-form-card {
      width: 285px;
      h4 {
        @include elementStyles($size: 14px, $color: $black, $fontFamily: $ibmplexMedium);
      }

      p {
        @include elementStyles($size: 13px, $color: $black , $fontFamily: $ibmplexMedium);
      }

      span {
        text-decoration: underline;
        @include elementStyles($size: 13px, $color: #4050b5, $fontFamily: $ibmplexMedium);
      }
    }

    .form-action-btn-grp {
      float: right;
    }
  }

  .technical-aircraft-froms-t007 {
    overflow: hidden;
    margin:-15px;

    .t007-form-v {
      background-color: #144067;
      // min-height: 75vh;
      margin: -3px;
      position: relative;
    

      .seats-container-t007 {
        // min-height: 100vh;
        width: 100%;
        overflow-x: scroll;
        margin-top: 5px;

        .seat-matrix {
          padding: 0 20px;
          display: none;
          //display: flex;
          align-items: center;

          .background-image-block {
            background-repeat: no-repeat;
            flex-shrink: 0;
            position: relative;


            width: 605px;
            height: 640px;
            background-position: -11px -2px;
            background-size: cover;
            position: relative;
            top: -23px;

            .cockpit-seats,
            .attended-seats {
              li {
                position: absolute;
                transform: translate(-50%, -50%);

                .disabled-seat {
                  opacity: 0.2 !important;
                  // pointer-events: none;
                }

                .current {
                  background-color: #488cc8;
                  color: #fff;
                }

                .finding-active {
                  background-color: #488cc8;
                  color: #fff;
                }

                .has-finding {
                  background-color: #ff6c6c;
                }


                &.fo-seat {
                  position: absolute;
                  right: 28%;
                  top: 25%;
                }

                &.cls-seat {
                  position: absolute;
                  right: 44%;
                  top: 37%;
                }

                &.o2-seat {
                  position: absolute;
                  right: 44%;
                  bottom: 34%;
                }

                &.o1-seat {
                  position: absolute;
                  right: 28%;
                  bottom: 25%;
                }

                //&.assitant-seat{
                //  position: absolute;
                //  right:10%;
                //  bottom: 25%;
                //}
                a {
                  height: 30px;
                  width: 32px;
                  background-color: transparent;
                  margin-bottom: 10px;
                  padding: 6px 8px 5px;
                  border-radius: 4px;
                  display: inline-block;
                  border: 1px solid #144067;
                  color: #144067;
                  font-family: $ibmplexRegular;
                  font-size: 12px;
                  cursor: pointer;
                  text-align: center;

                  &.active {
                    //background-color: #ff6c6c;
                    background-color: #488cc8;
                  }
                }
              }
            }
          }

          .cabin-seats {
            display: flex;
            align-items: center;
            height: 685px;
            position: relative;
            left: -13px;
            flex-shrink: 0;
            padding-right: 20px;

            img {
              &.cabin-image {
                position: absolute;
                width: 100%;
                top: -1px;
                bottom: 0;
                height: 638px;
                left: 12px;
              }
            }

            .seat-list {
              white-space: nowrap;
              margin-left: 20px;

              &.next-seat-block {
                margin-right: 40px;
              }

              &:first-child {
                margin-left: 200px;
              }

              li {
                ul {
                  margin-left: 0;
                  padding-top: 0;
                  white-space: nowrap;
                  overflow: hidden;

                  li {
                    a {
                      position: relative;
                      height: 30px;
                      width: 40px;
                      background-color: transparent;
                      margin-bottom: 10px;
                      padding: 6px 8px 5px;
                      border-radius: 4px;
                      display: inline-block;
                      border: 1px solid #144067;
                      color: #144067;
                      font-family: $ibmplexRegular;
                      font-size: 12px;
                      text-align: center;
                      text-transform: uppercase;

                      &.current {
                        background-color: #488cc8;
                        color: #fff;
                      }

                      &.finding-active {
                        background-color: #488cc8;
                        color: #fff;
                      }

                      &.has-finding {
                        background-color: #ff6c6c;
                      }

                      &.disabled-seat {
                        opacity: 0.2 !important;
                        // pointer-events: none;
                      }

                      &.window {
                        // opacity: .2;
                        background-position: center;
                        background-size: cover;
                        border: 1px solid rgba(20, 64, 103, 1);
                        background-image: url("https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/window_inactive.png");
                      }

                      &.window-selected {
                        background-image: url("https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/window_active.png");
                        border: 1px solid rgba(20, 64, 103, 1);
                      }
                    }

                    &:first-child {
                      a {
                        position: absolute;
                        top: 3%;
                      }
                    }

                    &:last-child {
                      a {
                        position: absolute;
                        bottom: 10%;
                      }
                    }

                    &:first-child,
                    &:last-child {
                      a {
                        height: 15px;
                        transform: translateX(0px);
                        -webkit-transform: translateX(0);
                        -moz-transform: translateX(0);
                      }
                    }

                    &:first-child {
                      margin-bottom: 10px;
                    }

                    &:last-child {
                      margin-top: 10px;

                      a {
                        margin-bottom: 0;
                      }
                    }

                    &.column-seperator {
                      margin-bottom: 25px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @keyframes plus {
    0% {
        transform: scale(1);
    }
    70% {
        transform: scale(1);
        box-shadow: rgba(90, 153, 212, 0) 0px 0px 0px 20px;
    }
    100% {
        transform: scale(1);
        box-shadow: rgba(90, 153, 212, 0) 0px 0px 0px 0px;
    }
  }
  .upload-image-margin{
    margin-top: 12px;
  }
  .files-upload-limit-t007{
    font-size: 12px;
    width: 380px;
    // background-color: #e9d273;
  }

  .form-t004-cn{
    background-color: #144067;
    min-height: 100vh;
    margin: -150px -15px -30px;
    padding: 120px 0 30px;
    .fa-circle{
      &::before{
        content: none;
      }
    }
    .indicater-detail-block{
      position: fixed;
      top: 130px;
      right: 30px;
      ul{
        li{
          color: #488cc8;
          font-size: 14px;
          margin-bottom: 3px;
          span{
            width: 12px;
            height: 12px;
            border-radius: 50%;
            display: inline-block;
            margin-right:4px;
            &.poor{
              background-color: #FB5353;
            }
            &.average{
              background-color: #ffae00;
            }
            &.good{
              background-color: #44bc29;
            }




          }
        }
      }
    }
    .t004-form-sidebar{
      width: 275px;
      position: absolute;
      top: 204px;
      left: 80px;
      .hide-index{
        label{
          display: inline-block;
          border-radius: 20px;
          // width: 100px;
          cursor: pointer;
          font-family: Conv_IBMPlexSans-Regular;
          font-size: 12px;
          color: #fff;
          background: rgba(0,0,0,.18);
          padding: 5px 24px;
          margin-bottom: 14px;
           display: inline-block;
           img{
             margin-left:23px;
           }
        }

      }
      ul{
        li{
          font-family: $ibmplexRegular;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
          background-color: #ffffff;
          margin-bottom:11px;
          padding: 12px;
          border-radius: 3px;
          position: relative;
          img{
            &.info-icon{
              cursor: pointer;
              margin-left:4px;
              display: inline-block;
            }
            &.edit-img{
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translate(-50%, -50%);
              cursor: pointer;
              z-index: 1;
            }

          }
        }
      }


    }
    .model-embarier-165{
      #indicater12{
        .indicater{
          bottom: 15% !important;
          left: 52% !important;
        }
        .section-detail{
          bottom: 13% !important;
          left: 54% !important;
        }
      }
      #indicater21{
        .indicater{
          bottom: 17% !important;
          right: 49.5% !important;
        }
        .section-detail{
          bottom: 15.5% !important;
          left: 48% !important;
        }
      }
    }
    .model-one,.model-two,.model-atr,.model-s2000,.model-three,.model-four,.model-nine,.model-ten,.model-seven,.model-five,.model-eight,.model-eleven {
      margin: 60px auto 0;
      min-height: 750px;
      .model-image {
        position: relative;
        min-width: 600px;
        max-width: 780px;
        min-height: 600px;
        display: block;
        margin: 0 auto;
        i{
          position: relative;
          display: block;
          font-weight: 100;
          text-align: center;
          letter-spacing: -1px;
          border: none;
          border-radius: 50%;
          width: 20px;
          height: 20px;
            &.blue, &.good {
              color: #488cc8;
              background: #488cc8;
            }
            &.green,  &.good {
              color: #44bc29;
              background: #44bc29;
            }
            &.yellow,  &.average {
              color: #ffae00;
              background: #ffae00;
            }
            &.red, &.poor {
              color: #FB5353;
              background: #FB5353;
              position: relative;
              display: block;
              font-weight: 100;
              text-align: center;
              letter-spacing: -1px;
              border: none;
              border-radius: 50%;
              cursor: pointer;
              box-shadow: 0 0 0 0 rgba(#5a99d4, .5);
              -webkit-animation: pulse 1.5s infinite;
              &:hover {
                -webkit-animation: none;
              }
            }
            &.red-color {
              color: #FB5353;
            }
            &.orange-color {
              color: #ffae00;
            }
            &.green-color {
              color: #44bc29;
            }
            &.blue-color {
              color: #488cc8;
            }
            &.white-color {
              color: #ffff;
            }
            &.red-color,&.orange-color,&.green-color,&.blue-color,&.white-color {
              position: absolute;
              top: 10px;
              left: 10px;
              font-weight: 100;
              text-align: center;
              letter-spacing: -1px;
              border: none;
              border-radius: 50%;
              cursor: pointer;
              font-size: 10px;
            }
          }
          .lavatories-gallies-block {
            .section-inner-wrap {
              min-height: 106px;
              z-index: 1;
              background-color:#fff;
              display: none;
              position: absolute;
              transform: translate(-50%, -50%);
              left: 50%;
              min-width: 51px;
              max-width: 300px;
              text-align: center;
              align-items: center;
              border-radius: 4px;
              .section-detail {
                position: relative;
                border-radius: 0 !important;
                transform: translate(0, 0);
                position: relative !important;
                min-width: 50px !important;
                max-width: 100px;
                float: left;
                right: 0 !important;
                left: 0 !important;
                a {
                  padding: 3px 15px;
                  min-width: 66px;
                }
              }
              &.fwd-galley {
                bottom: 76%;
              }
              &.mid-galley {
                bottom: 51%;
              }
              &.aft-galley {
                bottom: 17%;
              }
              &.fwd-lavatories {
                bottom: 73%;
              }
              &.mid-lavatories {
                bottom: 51%;
              }
              &.aft-lavatories {
                bottom: 20%;
              }
            }
          }
          .indicater-image {
            cursor: pointer;
            transition: all 1s ease;
            .indicater, .section-detail {
              position: absolute;
              transform: translate(-50%, -50%);
            }
            .indicater {
              z-index: 0;
              .indicater-number {
                position: absolute;
                color: #fff;
                font-size: 10px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 9;
                font-family: $ibmplexRegular;
              }
            }
            .section-detail {
              z-index: 90;
              text-align: center;
              background-color: #fff;
              min-width: 203px;
              position: absolute;
              border-radius: 3px;
              padding: 15px;
              visibility: hidden;
              opacity: 0;
              transition: visibility 0s, opacity 0.3s linear;
              &.active-menu {
                opacity: 1;
                visibility: visible;
                transition: visibility 0s, opacity 0.3s linear;
              }
              p {
                position: relative;
                font-family: $ibmplexSemiBold;
                font-size: 14px;
                color: #354052;
                text-align: center;
                margin-bottom: 10px;
              }
            }
            /*** newly added ***/
            &#indicater121{
              .indicater {
                top: 25px;
                left: 50%;
              }
              .section-detail {
                top: -40px;
                left: 50%;
              }
            }
            &#indicater113{
              .indicater{
                bottom: 26%;
                left: 46%;
              }
              .section-detail {
                bottom: 25%;
                left: 46%;
              }
            }

            &#indicater117{
              .indicater{
                bottom: 1%;
                right: 40%;
              }
              .section-detail {
                bottom: 0;
                right: 14%;
              }
            }
            &#indicater118{
              .indicater{
                top: 7.8%;
                left: 50%;
              }
              .section-detail {
                top: 0%;
                left: 50%;
              }
            }
            &#indicater119{
              .indicater{
                top: 4%;
                left: 47%;
              }
              .section-detail {
                top: -4%;
                  left: 48%;
              }
            }
            &#indicater120{
              .indicater{
                top: 5.5%;
                left: 50%;
              }
              .section-detail {
                top: -4%;
                  left: 50%;
              }
            }
            &#indicater116{
              .indicater{
                bottom: 6%;
                left: 50%;
              }
              .section-detail {
                bottom: 5%;
                left: 50%;
              }
            }
            &#indicater115{
              .indicater{
                bottom: 27px;
                left: 50%;
              }
              .section-detail {
                bottom: 13px;
                left: 50%;
              }
            }
            &#indicater114{
              .indicater{
                bottom: 8px;
                left: 43%;
              }
              .section-detail {
                bottom: -3px;
                left: 44%;
              }
            }
            &#indicater131{
              .indicater{
                left: 50%;
                bottom: 18%;
              }
              .section-detail{
                left: 50%;
                bottom: 16%;
              }
            }
            &#indicater129{
              .indicater{
                left: 50%;
                top: 23%;
              }
              .section-detail{
                left: 50%;
                top: 15%;
              }
            }
            &#indicater130{
              .indicater{
                top: 19%;
                left: 50%;
              }
              .section-detail{
                left: 50%;
                top: 10%;
              }
            }
            &#indicater133{
              .indicater{
                top: 5%;
                right: 45%;
              }
              .section-detail{
                right: 20%;
                top: -3%;
              }
            }
            &#indicater132{
              .indicater{
                top: 32%;
                left: 50%;
              }
              .section-detail{
                left: 50%;
                top: 24%;
              }
            }
            &#indicater125{
              .indicater{
                right: 34%;
                top: 47%;
              }
              .section-detail{
                right: 9%;
                top: 40%;
              }
            }
            &#indicater124{
              .indicater{
                top: 34%;
                left: 35.5%;
              }
              .section-detail{
                top: 26%;
                left: 34%;
              }
            }
            &#indicater123{
              .indicater{
                top: 47%;
                left: 35%;
              }
              .section-detail{
                top: 39%;
                left: 35%;
              }
            }
            &#indicater122{
              .indicater{
                top: 34%;
                right: 34%
              }
              .section-detail{
                top: 26%;
                right: 10%;
              }
            }

            /*** galley and lavatory****/
            &#indicater22 {
                .indicater {
                    top: 13%;
                    right: 44.5%;
                }
            }
            &#indicater30 {
                .indicater {
                    top: 44%;
                    left: 47%;
                }
            }
            &#indicater27 {
              .indicater {
                top: 13%;
                left: 47%;
              }
            }
            //
            &#indicater25 {
              z-index: 0;
              .indicater {
                top: 44%;
                right: 45%;
              }
            }
            //
            //
            &#indicater44 {
              .indicater {
                bottom: 21%;
                left: 47.5%;
              }
              .section-detail {
                bottom: 19%;
                left: 48%;
              }
            }
            &#indicater137 {
              .indicater {
                bottom: 21%;
                left: 47.5%;
              }
              .section-detail {
                bottom: 19%;
                left: 48%;
              }
            }
            &#indicater136 {
              .indicater {
                bottom: 23.4%;
                left: 48.7%;
              }
              .section-detail {
                bottom: 19%;
                left: 48%;
              }
            }
            &#indicater46 {
              .indicater {
                bottom: 21%;
                right: 45%;
              }
            }
            &#indicater49 {
              .indicater {
                bottom: 24%;
                left: 46%;
              }
            }
            /*** galley and lavatory****/
            &#indicater1 {
              .indicater {
                top: 18.5%;
                left: 44.7%;
              }
              .section-detail {
                top: 10.5%;
                left: 45.5%;
              }
            }
            &#indicater18 {
              .indicater {
                top: 45px;
                left: 50%;
              }
              .section-detail {
                top: -23px;
                left: 50%;
              }
            }
            &#indicater3, &#three-engine-indicater3 {
              .indicater {
                top: 7px;
                left: 50%;
              }
              .section-detail {
                top: -51px;
                left: 50%;
              }
            }
            &#indicater4 {
              .indicater {
                top: 18.5%;
                right: 43%;
              }
              .section-detail {
                top: 10.5%;
                right: 19%;
              }
            }
            //
            &#indicater39 {
              .indicater {
                top: 18.5%;
                left: 50%;
              }
              .section-detail {
                top: 10.5%;
                left: 50%;
              }
            }
            &#indicater135 {
              .indicater {
                top: 18.5%;
                left: 50%;
              }
              .section-detail {
                top: 10.5%;
                left: 50%;
              }
            }
            &#indicater134 {
              .indicater {
                top: 15.9%;
                left: 50%;
              }
              .section-detail {
                top: 10.5%;
                left: 50%;
              }
            }
            &#indicater11 {
              .indicater {
                bottom: 6%;
                left: 50%;
              }
              .section-detail {
                bottom: 4.5%;
                left: 50%;
              }
            }
            &#indicater12 {
              .indicater {
                bottom: 1%;
                left: 50%;
              }
              .section-detail {
                bottom: -16px;
                left: 50%;
              }
            }
            &#indicater34 {
              .indicater {
                top: 13%;
                left: 50%;
              }
              .section-detail {
                top: 5%;
                left: 50%;
              }
            }
            &#three-engine-indicater1 {
              .indicater {
                top: 18.5%;
                left: 43.5%;
              }
              .section-detail {
                top: 10.5%;
                left: 45.5%;
              }
            }
            &#three-engine-indicater18 {
              .indicater {
                top: 30px;
                left: 50%;
              }
              .section-detail {
                top: -3%;
                left: 50%;
              }
            }
            &#three-engine-indicater4 {
              .indicater {
                top: 18.5%;
                right: 41.5%;
              }
              .section-detail {
                top: 12%;
                right: 17%;
              }
            }
            &#three-engine-indicater39 {
              .indicater {
                top: 20%;
                left: 50%;
              }
              .section-detail {
                top: 13.5%;
                left: 50%;
              }
            }
            &#three-engine-indicater7 {
              .indicater {
                top: 74%;
                right: -6px;
              }
              .section-detail {
                top: 68%;
                right: -24%;
              }
            }
            &#three-engine-indicater8 {
              .indicater {
                top: 46%;
                right: 24%;
              }
              .section-detail {
                top: 39%;
                right: 0%;
              }
            }
            &#three-engine-indicater9 {
              .indicater {
                bottom: 38%;
                right: 37%;
              }
              .section-detail {
                bottom: 37%;
                right: 12%;
              }
            }
            &#three-engine-indicater10 {
              .indicater {
                bottom: 28%;
                right: 41%
              }
              .section-detail {
                bottom: 27.5%;
                right: 17.5%;
              }
            }
            &#three-engine-indicater13 {
              .indicater {
                bottom: 28%;
                left: 43.5%;
              }
              .section-detail {
                bottom: 26.5%;
                left: 43.5%;
              }
            }
            &#three-engine-indicater11 {
              .indicater {
                bottom: 6%;
                left: 50%;
              }
              .section-detail {
                bottom: 5%;
                left: 49.5%;
              }
            }
            &#three-engine-indicater12 {
              .indicater {
                top: 62%;
                left: 50%;
              }
              .section-detail {
                top: 55%;
                left: 49.5%;
              }
            }
            &#three-engine-indicater6 {
              .indicater {
                top: 50%;
                right: 41%;
              }
              .section-detail {
                top: 43%;
                right: 17%;
              }
            }
            &#three-engine-indicater14 {
              .indicater {
                top: 50%;
                left: 43%;
              }
              .section-detail {
                top: 43%;
                left: 43%;
              }
            }
            &#three-engine-indicater15 {
              .indicater {
                top: 74%;
                left: 0;
              }
              .section-detail {
                top: 68%;
                left: 0;
              }
            }
            &#three-engine-indicater16 {
              .indicater {
                top: 46%;
                left: 27%;
              }
              .section-detail {
                top: 39%;
                left: 27%;
              }
            }
            &#three-engine-indicater17 {
              .indicater {
                bottom: 38%;
                left: 40%;
              }
              .section-detail {
                bottom: 37%;
                left: 40%;
              }
            }
            &#indicater2, &#three-engine-indicater2 {
              .indicater {
                top: 10%;
                left: 50%;
              }
              .section-detail {
                top: 3%;
                left: 50%;
              }
            }
            &#indicater19{
              .indicater {
                top: 23%;
                left: 50%;
              }
              .section-detail {
                top: 15%;
                left: 50%;
              }
            }
            &#indicater139{
              .indicater {
                top: 23%;
                left: 50%;
              }
              .section-detail {
                top: 15%;
                left: 50%;
              }
            }
            &#indicater79 {
              .indicater {
                top: 47%;
                left: 50%;
              }
              .section-detail {
                top: 38%;
                left: 50%;
              }
            }
            &#three-engine-indicater79 {
              .indicater {
                top: 47%;
                left: 50%;
              }
              .section-detail {
                top: 40%;
                left: 50%;
              }
            }
            &#three-engine-indicater77 {
              .indicater {
                top: 10%;
                left: 44%
              }
              .section-detail {
                top: 3.5%;
                left: 45%;
              }
            }
            &#indicater78 {
              .indicater {
                top: 28%;
                left: 50%;
              }
              .section-detail {
                top: 20%;
                left: 50%;
              }
            }
            &#three-engine-indicater78 {
              .indicater {
                top: 28%;
                left: 50%;
              }
              .section-detail {
                top: 22%;
                left: 50%;
              }
            }
            &#indicater77 {
              .indicater {
                top: 10%;
                left: 46%
              }
              .section-detail {
                top: 2%;
                left: 46%;
              }
            }
            &#three-engine-indicater19 {
              .indicater {
                top: 23%;
                left: 50%;
              }
              .section-detail {
                top: 15%;
                left: 50%;
              }
            }
            &#three-engine-indicater21 {
              .indicater {
                bottom: 31%;
                right: 41%;
              }
              .section-detail {
                bottom: 29.5%;
                right: 17%;
              }
            }
            &#three-engine-indicater22 {
              .indicater {
                top: 13%;
                right: 44%;
              }
              .section-detail {
                top: 11%;
                right: 18%;
              }
            }
            &#three-engine-indicater23 {
              .indicater {
                top: 26%;
                left: 50%;
              }
              .section-detail {
                top: 18%;
                left: 50%;
              }
            }
            &#three-engine-indicater24 {
              .indicater {
                top: 20%;
                left: 46%;
              }
              .section-detail {
                top: 11%;
                left: 46%;
              }
            }
            &#three-engine-indicater30 {
              .indicater {
                top: 49%;
                right: 44%;
              }
              .section-detail {
                top: 37%;
                right: 18%;
              }
            }
            &#three-engine-indicater26 {
              .indicater {
                top: 42%;
                left: 50%;
              }
              .section-detail {
                top: 33%;
                left: 50%;
              }
            }
            &#three-engine-indicater25 {
              .indicater {
                top: 57%;
                left: 50%;
              }
              .section-detail {
                top: 33%;
                left: 45%;
              }
            }
            &#three-engine-indicater31 {
              .indicater {
                top: 42%;
                left: 50%;
              }
              .section-detail {
                top: 33%;
                left: 50%;
              }
            }
            &#three-engine-indicater36 {
              .indicater {
                top: 42%;
                left: 46%;
              }
              .section-detail {
                top: 33%;
                left: 45%;
              }
            }
            &#three-engine-indicater5 {
              .indicater {
                top: 22%;
                right: 41%;
              }
              .section-detail {
                top: 14%;
                right: 18%;
              }
            }
            &#three-engine-indicater44 {
              .indicater {
                bottom: 19%;
                left: 47.5%;
              }
              .section-detail {
                bottom: 17%;
                left: 48%;
              }
            }
            &#three-engine-indicater45 {
              .indicater {
                top: 46%;
                left: 46%;
              }
              .section-detail {
                top: 37%;
                left: 45%;
              }
            }
            &#three-engine-indicater46 {
              .indicater {
                bottom: 19%;
                right: 44%;
              }
              .section-detail {
                bottom: 26%;
                right: 18%;
              }
            }
            &#three-engine-indicater47 {
              .indicater {
                bottom: 30%;
                left: 50%;
              }
              .section-detail {
                bottom: 30%;
                left: 50%;
              }
            }
            &#three-engine-indicater48 {
              .indicater {
                bottom: 27%;
                left: 46%;
              }
              .section-detail {
                bottom: 25%;
                left: 45%;
              }
            }
            &#three-engine-indicater49 {
              .indicater {
                bottom: 22%;
                left: 46%;
              }
              .section-detail {
                bottom: 20%;
                right: 19%;
              }
            }
            &#three-engine-indicater50 {
              .indicater {
                bottom: 22%;
                left: 50%;
              }
              .section-detail {
                bottom: 20%;
                left: 50%;
              }
            }
            &#three-engine-indicater51 {
              .indicater {
                bottom: 22%;
                left: 46%;
              }
              .section-detail {
                bottom: 20%;
                left: 45%;
              }
            }
            &#three-engine-indicater27 {
              .indicater {
                top: 13%;
                right: 44%;
              }
              .section-detail {
                top: 16%;
                right: 18%;
              }
            }
            &#three-engine-indicater28 {
              .indicater {
                top: 29%;
                left: 50%;
              }
              .section-detail {
                top: 20%;
                left: 50%;
              }
            }
            &#three-engine-indicater29 {
              .indicater {
                top: 25%;
                left: 46%;
              }
              .section-detail {
                top: 16%;
                left: 45%;
              }
            }
            &#three-engine-indicater32 {
              .indicater {
                bottom: 15%;
                left: 50%;
              }
              .section-detail {
                bottom: 13%;
                left: 50%;
              }
            }
            &#flight-indicater1 {
              .indicater {
                top: 10%;
                left: 41.5%;
              }
              .section-detail {
                top: 2%;
                left: 46%;
              }
            }
            &#flight-indicater2 {
              .indicater {
                top: 15%;
                left: 50%;
              }
              .section-detail {
                top: 6%;
                left: 50%;
              }
            }
            &#flight-indicater3 {
              .indicater {
                top: 3%;
                left: 50%;
              }
              .section-detail {
                top: -5%;
                left: 50%;
              }
            }
            &#flight-indicater4 {
              .indicater {
                top: 10%;
                right: 39%;
              }
              .section-detail {
                top: 2%;
                right: 5%;
              }
            }
            &#flight-indicater6 {
              .indicater {
                top: 20%;
                right: 42%;
              }
              .section-detail {
                top: 12%;
                right: 11%;
              }
            }
            &#flight-indicater7 {
              .indicater {
                top: 22%;
                left: 65%;
              }
              .section-detail {
                top: 22%;
                left: 65%;
              }
            }
            &#flight-indicater8 {
              .indicater {
                top: 37%;
                left: 58%;
              }
              .section-detail {
                top: 37%;
                left: 58%;
              }
            }
            &#flight-indicater9 {
              .indicater {
                top: 28%;
                right: 41%;
              }
              .section-detail {
                top: 18%;
                right: 9%;
              }
            }
            &#flight-indicater10 {
              .indicater {
                top: 38%;
                left: 54%;
              }
              .section-detail {
                top: 30%;
                left: 53%;
              }
            }
            &#flight-indicater11 {
              .indicater {
                bottom: 43%;
                left: 50%;
              }
              .section-detail {
                bottom: 42%;
                left: 50%;
              }
            }
            &#flight-indicater12 {
              .indicater {
                top: 30%;
                left: 50%;
              }
              .section-detail {
                top: 21.5%;
                left: 50%;
              }
            }
            &#flight-indicater13 {
              .indicater {
                top: 38%;
                left: 44%;
              }
              .section-detail {
                top: 30%;
                left: 45%;
              }
            }
            &#flight-indicater14 {
              .indicater {
                top: 20%;
                left: 45%;
              }
              .section-detail {
                top: 12%;
                left: 45%;
              }
            }
            &#flight-indicater16 {
              .indicater {
                top: 34%;
                left: 50%;
              }
              .section-detail {
                top: 24%;
                left: 50%;
              }
            }
            &#flight-indicater17 {
              .indicater {
                top: 28%;
                left: 45%;
              }
              .section-detail {
                top: 18%;
                left: 45%;
              }
            }
            &#flight-indicater18 {
              .indicater {
                top: 10%;
                left: 50%;
              }
              .section-detail {
                top: 3%;
                left: 50%;
              }
            }
            &#flight-indicater19 {
              .indicater {
                top: 25%;
                left: 50%;
              }
              .section-detail {
                top: 16%;
                left: 50%;
              }
            }
            &#flight-indicater103 {
              .indicater {
                top: 15%;
                left: 39%;
              }
              .section-detail {
                  top: 7%;
                  left: 36%;
              }
          }

          &#flight-indicater104 {
              .indicater {
                  top: 15%;
                  left: 50%;
              }
              .section-detail {
                  top: 6%;
                  left: 50%;
              }
          }
          &#flight-indicater105 {
              .indicater {
                  top: 15%;
                  left: 61.5%;
              }
              .section-detail {
                  top: 6%;
                  left: 65%;
              }
          }

          &#flight-indicater106 {
              .indicater {
                top: 33%;
                right: 47.5%;
              }
              .section-detail {
                top: 24%;
                right: 16%;
              }
          }
          &#flight-indicater107 {
              .indicater {
                top: 33%;
                left: 58%;
              }
              .section-detail {
                top: 24%;
                left: 59%;
              }
          }
          &#flight-indicater108 {
              .indicater {
                top: 45%;
                left: 53%;
              }
              .section-detail {
                top: 36%;
                left: 54%;
              }
          }
          &#flight-indicater109 {
              .indicater {
                top: 67%;
                right: 46%;
              }
              .section-detail {
                top: 58%;
                right: 14%;
              }
          }
          &#flight-indicater110 {
              .indicater {
                top: 90%;
                left: 50%;
              }
              .section-detail {
                top: 80%;
                left: 51%;
              }
          }
          &#flight-indicater111 {
              .indicater {
                top: 56%;
                left: 50%;
              }
              .section-detail {
                top: 47.5%;
                left: 50%;
              }
          }
          &#flight-indicater112 {
              .indicater {
                top: 33%;
                left: 42%;
              }
              .section-detail {
                top: 24%;
                left: 43%;
              }
          }
         }
        }
      }
      .model-eleven {
        .indicater-image {
          &#indicater5 {
            .indicater {
              top: 22%;
              right: 52.2%;
            }
            .section-detail {
              top: 14%;
              left: 46%;
            }
          }
          &#indicater49 {
            .indicater {
              bottom: 26% !important;
              left: 48% !important;
            }
            .aft-lavatories {
              bottom: 23% !important;
            }
          }
          &#indicater6 {
            .indicater {
              top: 50%;
              right: 43%;
            }
            .section-detail {
              top: 42%;
              right: 19%;
            }
          }
          &#indicater7 {
            .indicater {
              top: 63%;
              right: 6%;
            }
            .section-detail {
              top: 55%;
              right: -179px;
            }
          }
          &#indicater8 {
            .indicater {
              bottom: 32%;
              right: 39%;
            }
            .section-detail {
              bottom: 29.5%;
              right: 11%;
            }
          }
          &#indicater9 {
            .indicater {
              bottom: 43%;
              right: 38%;
            }
            .section-detail {
              bottom: 41%;
              right: 14%;
            }
          }
          &#indicater10 {
            .indicater {
              bottom: 34%;
              right: 43%;
            }
            .section-detail {
              bottom: 32%;
              right: 15%;
            }
          }
          &#indicater13 {
            .indicater {
              bottom: 34%;
              left: 45%;
            }
            .section-detail {
              bottom: 32%;
              left: 45%;
            }
          }
          &#indicater14 {
            .indicater {
              top: 50%;
              left: 45%;
            }
            .section-detail {
              top: 42%;
              left: 46.5%;
            }
          }
          &#indicater15 {
            .indicater {
              top: 63%;
              left: 8.4%;
            }
            .section-detail {
              top: 55%;
              left: 2.4%;
            }
          }
          &#indicater16 {
            .indicater {
              bottom: 32%;
              left: 41%;
            }
            .section-detail {
              top: 58%;
              left: 41%;
            }
          }
          &#indicater17 {
            .indicater {
              bottom: 43%;
              left: 40%;
            }
            .section-detail {
              bottom: 30%;
              left: 40%;
            }
          }
          &#indicater21 {
            .indicater {
              bottom: 38%;
              right: 52.5%;
            }
            .section-detail {
              bottom: 36.5%;
              left: 45%;
            }
          }
          &#indicater52 {
            .indicater {
              bottom: 43%;
              left: 46%;
            }
            .section-detail {
              bottom: 41%;
              left: 46%;
            }
          }
          &#indicater53 {
            .indicater {
              bottom: 43%;
              right: 44%;
            }
            .section-detail {
              bottom: 41%;
              right: 20%;
            }
          }
          &#indicater54 {
            .indicater {
              bottom: 40%;
              left: 50%;
            }
            .section-detail {
              bottom: 37%;
              left: 50%;
            }
          }
        }
      }
      .model-eight {
        .indicater-image {
          .fwd-galley {
            bottom: 82% !important;
          }
          &#indicater5 {
            .indicater {
              top: 22%;
              right: 43.2%;
            }
            .section-detail {
              top: 14%;
              right: 20%;
            }
          }
          &#indicater6 {
            .indicater {
              top: 50%;
              right: 43%;
            }
            .section-detail {
              top: 42%;
              right: 19%;
            }
          }
          &#indicater7 {
            .indicater {
              top: 67%;
              right: 0px;
            }
            .section-detail {
              top: 58%;
              right: -179px;
            }
          }
          &#indicater8 {
            .indicater {
              top: 35%;
              right: 33.5%;
            }
            .section-detail {
              top: 27%;
              right: 9%;
            }
          }
          &#indicater9 {
            .indicater {
              bottom: 43%;
              right: 38%;
            }
            .section-detail {
              bottom: 41%;
              right: 14%;
            }
          }
          &#indicater10 {
            .indicater {
              bottom: 29%;
              right: 43%;
            }
            .section-detail {
              bottom: 27%;
              right: 18%;
            }
          }
          &#indicater13 {
            .indicater {
              bottom: 29%;
              left: 44.5%;
            }
            .section-detail {
              bottom: 27%;
              left: 45%;
            }
          }
          &#indicater14 {
            .indicater {
              top: 50%;
              left: 45%;
            }
            .section-detail {
              top: 42%;
              left: 46.5%;
            }
          }
          &#indicater15 {
            .indicater {
              top: 66%;
              left: 2.4%;
            }
            .section-detail {
              top: 58%;
              left: 2.4%;
            }
          }
          &#indicater16 {
            .indicater {
              top: 35%;
              left: 35.5%;
            }
            .section-detail {
              top: 26%;
              left: 34%;
            }
          }
          &#indicater17 {
            .indicater {
              bottom: 43%;
              left: 40%;
            }
            .section-detail {
              bottom: 41%;
              left: 41%;
            }
          }
          &#indicater21 {
            .indicater {
              bottom: 33%;
              right: 43.3%;
            }
            .section-detail {
              bottom: 31%;
              right: 19%;
            }
          }
          &#indicater52 {
            .indicater {
              bottom: 40%;
              left: 46%;
            }
            .section-detail {
              bottom: 38%;
              left: 46%;
            }
          }
          &#indicater53 {
            .indicater {
              bottom: 40%;
              right: 44%;
            }
            .section-detail {
              bottom: 38%;
              right: 20%;
            }
          }
          &#indicater54 {
            .indicater {
              bottom: 40%;
              left: 50%;
            }
            .section-detail {
              bottom: 37%;
              left: 50%;
            }
          }
        }
      }
      .model-seven {
        .indicater-image {
          &#indicater5 {
            .indicater {
              top: 22%;
              right: 42.2%;
            }

            .section-detail {
              top: 14%;
              left: 46%;
            }
          }

          &#indicater6 {
            .indicater {
              top: 50%;
              right: 41.3%;
            }

            .section-detail {
              top: 42%;
              right: 19%;
            }
          }

          &#indicater7 {
            .indicater {
              top: 71%;
              right: 0%;
            }

            .section-detail {
              top: 55%;
              right: -179px;
            }
          }

          &#indicater8 {
            .indicater {
              bottom: 48%;
              right: 25%;
            }

            .section-detail {
              bottom: 29.5%;
              right: 11%;
            }
          }

          &#indicater9 {
            .indicater {
              bottom: 42%;
              right: 36%;
            }

            .section-detail {
              bottom: 41%;
              right: 14%;
            }
          }

          &#indicater10 {
            .indicater {
              bottom: 30%;
              right: 41%;
            }

            .section-detail {
              bottom: 32%;
              right: 15%;
            }
          }

          &#indicater13 {
            .indicater {
              bottom: 30%;
              left: 44%;
            }

            .section-detail {
              bottom: 32%;
              left: 45%;
            }
          }

          &#indicater14 {
            .indicater {
              top: 50%;
              left: 44%;
            }

            .section-detail {
              top: 42%;
              left: 46.5%;
            }
          }

          &#indicater15 {
            .indicater {
              top: 71%;
              left: 2.4%;
            }

            .section-detail {
              top: 55%;
              left: 2.4%;
            }
          }

          &#indicater16 {
            .indicater {
              bottom: 48%;
              left: 27.5%;
            }

            .section-detail {
              top: 58%;
              left: 41%;
            }
          }

          &#indicater17 {
            .indicater {
              bottom: 42%;
              left: 38%;
            }

            .section-detail {
              bottom: 30%;
              left: 40%;
            }
          }

          &#indicater21 {
            .indicater {
              bottom: 33%;
              right: 41.3%;
            }

            .section-detail {
              bottom: 31%;
              right: 19%;
            }
          }
        }
      }
      .model-one,.model-ten,.model-nine {
        .indicater-image {
          &#indicater5 {
            .indicater {
              top: 22%;
              right: 43.2%;
            }
            .section-detail {
              top: 14%;
              right: 19%;
            }
          }
          &#indicater7 {
            .indicater {
              top: 59%;
              right: 0px;
            }
            .section-detail {
              top: 51%;
              right: -179px;
            }
          }
          &#indicater13 {
            .indicater {
              bottom: 28%;
              left: 44.5%;
            }
            .section-detail {
              bottom: 26%;
              left: 45%;
            }
          }
          &#indicater6 {
            .indicater {
              top: 39%;
              right: 43%;
            }
            .section-detail {
              top: 31%;
              right: 18%;
            }
          }
          &#indicater14 {
            .indicater {
              top: 39%;
              left: 44%;
            }
            .section-detail {
              top: 31%;
              left: 44%;
            }
          }
          &#indicater8 {
            .indicater {
              top: 35%;
              right: 29%;
            }
            .section-detail {
              top: 27%;
              right: 5%;
            }
          }
          &#indicater10 {
            .indicater {
              bottom: 28%;
              right: 43%;
            }
            .section-detail {
              bottom: 26%;
              right: 18%;
            }
          }
          &#indicater15 {
            .indicater {
              top: 58%;
              left: 2.4%;
            }
            .section-detail {
              top: 50%;
              left: 2.4%;
            }
          }
          &#indicater_16 {
            .indicater {
              top: 41%;
              left: 19%;
            }
            .section-detail {
              top: 26%;
              left: 32%;
            }
          }
          &#indicater_8 {
            .indicater {
              top: 35%;
              left: 31.5%;
            }
            .section-detail {
              top: 26%;
              left: 32%;
            }
          }
          &#indicater_32 {
            .indicater {
              top: 35%;
              right: 29%;
            }
            .section-detail {
              top: 26%;
              left: 32%;
            }
          }
          &#indicater16 {
            .indicater {
              top: 35%;
              left: 31.5%;
            }
            .section-detail {
              top: 26%;
              left: 32%;
            }
          }
          &#indicater9 {
            .indicater {
              bottom: 49%;
              right: 37%;
            }
            .section-detail {
              bottom: 47%;
              right: 12%;
            }
          }
          &#indicater17 {
            .indicater {
              bottom: 49%;
              left: 38.4%;
            }
            .section-detail {
              bottom: 47%;
              left: 38.4%;
            }
          }
          &#indicater33 {
            .indicater {
              top: 41%;
              right: 17%;
            }
            .section-detail {
              top: 32%;
              right: -8%;
            }
          }
          &#indicater21 {
            .indicater {
              bottom: 38%;
              right: 43.5%;
            }
            .section-detail {
              bottom: 36%;
              right: 19%;
            }
          }
          &#indicater32 {
            .indicater {
              top: 41%;
              left: 19%;
            }
            .section-detail {
              top: 31%;
              left: 19%;
            }
          }
          &#indicater52 {
            .indicater {
              top: 50%;
              left: 46%;
            }
            .section-detail {
              top: 42%;
              left: 46%;
            }
          }
          &#indicater53 {
            .indicater {
              top: 50%;
              right: 44%;
            }
            .section-detail {
              top: 42%;
              right: 19%;
            }
          }
          &#indicater54 {
            .indicater {
              top: 51.5%;
              left: 50%;
            }
            .section-detail {
              top: 42%;
              left: 50%;
            }
          }
        }
      }
      .model-nine {
        .indicater-image {
          &#indicater138 {
            .indicater {
              top: 21.1%;
              left: 50%;
            }

            .section-detail {
              top: 10.5%;
              left: 50%;
            }
          }
          &#indicater139 {
            .indicater {
              top: 24% !important;
              left: 50%;
            }
            .section-detail {
              top: 15%;
              left: 50%;
            }
          }
        }

      }
      .model-five {
        .indicater-image {
          &#indicater5 {
            .indicater {
              top: 22%;
              right: 53.2%;
            }
            .section-detail {
              top: 14%;
              right: 29%;
            }
          }
          &#indicater7 {
            .indicater {
              top: 67%;
              right: 0px;
            }
            .section-detail {
              top: 58%;
              right: -179px;
            }
          }
          &#indicater8 {
            .indicater {
              top: 35%;
              right: 33.5%;
            }
            .section-detail {
              top: 27%;
              right: 9%;
            }
          }
          &#indicater9 {
            .indicater {
              bottom: 37%;
              left: 44.4%;
            }
            .section-detail {
              bottom: 34%;
              left: 44.4%;
            }
          }
          &#indicater10 {
            .indicater {
              bottom: 32%;
              right: 43%;
            }
            .section-detail {
              bottom: 30%;
              right: 18%;
            }
          }
          &#indicater13 {
            .indicater {
              bottom: 32%;
              left: 45%;
            }
            .section-detail {
              bottom: 30%;
              left: 45%;
            }
          }
          &#indicater6 {
            .indicater {
              top: 43%;
              right: 41%;
            }
            .section-detail {
              top: 36%;
              right: 16%;
            }
          }
          &#indicater14 {
            .indicater {
              top: 39%;
              left: 43%;
            }
            .section-detail {
              top: 31%;
              left: 44%;
            }
          }
          &#indicater15 {
            .indicater {
              top: 66%;
              left: 2.4%;
            }
            .section-detail {
              top: 58%;
              left: 2.4%;
            }
          }
          &#indicater16 {
            .indicater {
              top: 35%;
              left: 35.5%;
            }
            .section-detail {
              top: 26%;
              left: 34%;
            }
          }
          &#indicater17 {
            .indicater {
              bottom: 37%;
              right: 43%;
            }
            .section-detail {
              bottom: 34%;
              right: 18%;
            }
          }
        }
      }
      .model-two {
        .indicater-image {
          &#indicater17 {
            .indicater {
              bottom: 49%;
              left: 41%;
            }
            .section-detail {
              bottom: 48%;
              left: 41%;
            }
          }
          &#indicater122{
            .indicater {
              top: 34%;
              right: 34%
            }
            .section-detail {
              top: 26%;
              right: 10%;
            }
          }
          &#indicater124 {
            .indicater {
              top: 34%;
              left: 35.5%;
            }
            .section-detail {
              top: 26%;
              left: 34%;
            }
          }
          &#indicater56{
            .indicater {
              top: 39%;
              right: 34%
            }
            .section-detail {
              top: 31%;
              right: 10%;
            }
          }
          &#indicater55 {
            .indicater {
              top: 39%;
              left: 35.5%;
            }
            .section-detail {
              top: 31%;
              left: 34%;
            }
          }
          &#indicater8 {
            .indicater {
              top: 68%;
              right: 40.3%;
            }
            .section-detail {
              top: 34%;
              right: 31.5%;
            }
          }
          &#indicater16 {
            #model12{
              top: 68%;
              left: 42.5%;
            }
            .indicater {
              top: 68%;
              left: 42.5%;
            }
            .section-detail {
              top: 26%;
              left: 34%;
            }
          }
          &#indicater9 {
            .indicater {
              bottom: 49%;
              right: 39%;
            }
            .section-detail {
              bottom: 47%;
              right: 14%;
            }
          }
          &#indicater49 {
            .indicater {
              bottom: 24%;
              left: 48% !important;
            }
            .aft-lavatories {
              bottom: 18% !important;
            }
          }
          .aft-galley {
            bottom: 13% !important;
          }
          .mid-lavatories {
            bottom: 35% !important;
          }
        }
      }
      .model-atr {
        .indicater-image {
          &#indicater17 {
            .indicater {
              bottom: 49%;
              left: 41%;
            }
            .section-detail {
              bottom: 48%;
              left: 41%;
            }
          }
          &#indicater122{
            .indicater {
              top: 34%;
              right: 34%
            }
            .section-detail {
              top: 26%;
              right: 10%;
            }
          }
          &#indicater124 {
            .indicater {
              top: 34%;
              left: 35.5%;
            }
            .section-detail {
              top: 26%;
              left: 34%;
            }
          }
          &#indicater56{
            .indicater {
              top: 39%;
              right: 34%
            }
            .section-detail {
              top: 31%;
              right: 10%;
            }
          }
          &#indicater55 {
            .indicater {
              top: 39%;
              left: 35.5%;
            }
            .section-detail {
              top: 31%;
              left: 34%;
            }
          }
          &#indicater8 {
            .indicater {
              top: 38%;
              right: 33.8%;
            }
            .section-detail {
              top: 34%;
              right: 31.5%;
            }
          }
          &#indicater16 {
            #model12{
              top: 68%;
              left: 42.5%;
            }
            .indicater {
              top: 38%;
                left: 35.5%;
            }
            .section-detail {
              top: 26%;
              left: 34%;
            }
          }
          &#indicater9 {
            .indicater {
              bottom: 49%;
              right: 39%;
            }
            .section-detail {
              bottom: 47%;
              right: 14%;
            }
          }
          &#indicater49 {
            .indicater {
              bottom: 24%;
              left: 48% !important;
            }
            .aft-lavatories {
              bottom: 18% !important;
            }
          }
          .aft-galley {
            bottom: 13% !important;
          }
          .mid-lavatories {
            bottom: 35% !important;
          }
        }
      }
      .model-s2000 {
        .indicater-image {
          &#indicater17 {
            .indicater {
              bottom: 49%;
              left: 41%;
            }

            .section-detail {
              bottom: 48%;
              left: 41%;
            }
          }

          &#indicater52 {
            .indicater {
              bottom: 43%;
              right: 44%;
            }

            .section-detail {
              bottom: 41%;
              left: 46%;
            }
          }

          &#indicater53 {
            .indicater {
              bottom: 43%;
              left: 46.7%;
            }

            .section-detail {
              bottom: 41%;
              right: 20%;
            }
          }

          &#indicater54 {
            .indicater {
              bottom: 40%;
              left: 50%;
            }

            .section-detail {
              bottom: 37%;
              left: 50%;
            }
          }

          &#indicater122 {
            .indicater {
              top: 34%;
              right: 34%
            }

            .section-detail {
              top: 26%;
              right: 10%;
            }
          }

          &#indicater124 {
            .indicater {
              top: 34%;
              left: 35.5%;
            }

            .section-detail {
              top: 26%;
              left: 34%;
            }
          }

          &#indicater56 {
            .indicater {
              top: 39%;
              right: 34%
            }

            .section-detail {
              top: 31%;
              right: 10%;
            }
          }

          &#indicater55 {
            .indicater {
              top: 39%;
              left: 35.5%;
            }

            .section-detail {
              top: 31%;
              left: 34%;
            }
          }

          &#indicater8 {
            .indicater {
              top: 38%;
              right: 33.8%;
            }

            .section-detail {
              top: 34%;
              right: 31.5%;
            }
          }

          &#indicater16 {
            #model12 {
              top: 68%;
              left: 42.5%;
            }

            .indicater {
              top: 38%;
              left: 35.5%;
            }

            .section-detail {
              top: 26%;
              left: 34%;
            }
          }

          &#indicater9 {
            .indicater {
              bottom: 49%;
              right: 39%;
            }

            .section-detail {
              bottom: 47%;
              right: 14%;
            }
          }

          &#indicater49 {
            .indicater {
              bottom: 24%;
              left: 48% !important;
            }

            .aft-lavatories {
              bottom: 18% !important;
            }
          }

          .aft-galley {
            bottom: 13% !important;
          }

          .mid-lavatories {
            bottom: 35% !important;
          }
        }
      }
      .model-three {
        .indicater-image {
          &#indicater8{
            .indicater {
              top: 40%;
              right: 34%
            }
            .section-detail {
              top: 32%;
              right: 10%;
            }
          }
          &#indicater5 {
            .indicater {
              top: 22%;
              right: 53.2%;
            }
            .section-detail {
              top: 14%;
              right: 29%;
            }
          }
          &#indicater17 {
            .indicater {
              bottom: 50%;
              left: 35%;
            }
            .section-detail {
              bottom: 48%;
              left: 35%;
            }
          }
          &#indicater9 {
            .indicater {
              bottom: 50%;
              right: 35%;
            }
            .section-detail {
              bottom: 48%;
              right: 11%;
            }
          }
          &#indicater16{
            .indicater {
              top: 40%;
              left: 35.5%;
            }
            .section-detail {
              top: 32%;
              left: 34%;
            }
          }
          &#indicater49 {
            .indicater {
              bottom: 24%;
              left: 48% !important;
            }
            .aft-lavatories {
              bottom: 18% !important;
            }
          }
          .aft-galley {
            bottom: 13% !important;
          }
          .mid-lavatories {
            bottom: 35% !important;
          }
        }
      }
      .model-three, .model-two, .model-crj, .model-atr, .model-s2000{
        .indicater-image {
          &#indicater5 {
            .indicater {
              top: 22%;
              right: 52.2%;
            }
            .section-detail {
              top: 14%;
              left: 46%;
            }
          }
          &#indicater21 {
            .indicater {
              bottom: 38%;
              right: 52.5%;
            }
            .section-detail {
              bottom: 36%;
              left: 46%;
            }
          }
          &#indicater10 {
            .indicater {
              bottom: 31%;
              right: 43%;
            }
            .section-detail {
              bottom: 29%;
              right: 19%;
            }
          }
          &#indicater55 {
            .indicater {
              top: 39%;
              left: 35.5%;
            }
            .section-detail {
              top: 26%;
              left: 34%;
            }
          }
          &#indicater13 {
            .indicater {
              bottom: 31%;
              left: 46%;
            }
            .section-detail {
              bottom: 29%;
              left: 46%;
            }
          }
          &#indicater6 {
            .indicater {
              top: 49%;
              right: 44%
            }
            .section-detail {
              top: 41%;
              right: 18%;
            }
          }
          &#indicater14 {
            .indicater {
              top: 49%;
              left: 46%;
            }
            .section-detail {
              top: 41%;
              left: 45%;
            }
          }
          &#indicater15 {
            #model12{
              bottom: 36%;
              left: 10%;
            }
            .indicater {
              bottom: 50%;
              left: 2.4%;
            }
            .section-detail {
              bottom: 48%;
              left: 2.4%;
            }
          }
          &#indicater7 {
            #model12{
              bottom: 36% ;
              right: 7.6% ;
            }
            .indicater {
              bottom: 50%;
              right: -12px;
            }
            .section-detail {
              top: 39%;
              right: -187px;
            }
          }
          &#indicater56 {
            .indicater {
              top: 39%;
              right: 34%
            }
            .section-detail {
              top: 26%;
              right: 10%;
            }
          }
          &#indicater19 {
            .indicater {
              top: 23%;
              left: 50%;
            }
            .section-detail {
              top: 15%;
              left: 50%;
            }
          }
          &#indicater139 {
            .indicater {
              top: 23%;
              left: 50%;
            }
            .section-detail {
              top: 15%;
              left: 50%;
            }
          }
        }
      }
      .model-four {
        .indicater-image {
          &#indicater1 {
            .indicater {
              top: 7px;
              left: 50%;
            }
            .section-detail {
              top: 7px;
              left: 50%;
            }
          }
          &#indicater3 {
            .indicater {
              top: 146px;
              left: 50%;
            }
            .section-detail {
              top: 146px;
              left: 50%;
            }
          }
          &#indicater4 {
            .indicater {
              top: 18.5%;
              right: 43%;
            }
            .section-detail {
              top: 10.5%;
              right: 41%;
            }
          }
          &#indicater5 {
            .indicater {
              top: 185px;
              left: 50%;
            }
            .section-detail {
              top: 185px;
              left: 50%;
            }
          }
          &#indicater7 {
            .indicater {
              top: 34%;
              right: 31.5%;
            }
            .section-detail {
              top: 34%;
              right: 31.5%;
            }
          }
          &#indicater8 {
            .indicater {
              top: 34%;
              right: 31.5%;
            }
            .section-detail {
              top: 34%;
              right: 31.5%;
            }
          }
          &#indicater9 {
            .indicater {
              top: 34%;
              right: 31.5%;
            }
            .section-detail {
              top: 34%;
              right: 31.5%;
            }
          }
          &#indicater17 {
            .indicater {
              bottom: 43%;
              right: 43%;
            }
            .section-detail {
              bottom: 41%;
              right: 18%;
            }
          }
          &#indicater6 {
            .indicater {
              top: 43%;
              right: 41%;
            }
            .section-detail {
              top: 36%;
              right: 16%;
            }
          }
          &#indicater14 {
            .indicater {
              top: 43%;
              left: 43%;
            }
            .section-detail {
              top: 36%;
              left: 44%;
            }
          }
          &#indicater16 {
            .indicater {
              top: 34%;
              left: 35.5%;
            }
            .section-detail {
              top: 26%;
              left: 34%;
            }
          }
          &#indicater13 {
            .indicater {
              bottom: 38%;
              left: 45%;
            }
            .section-detail {
              bottom: 38%;
              left: 45%;
            }
          }
          &#indicater14 {
            .indicater {
              top: 42%;
              left: 47%;
            }
            .section-detail {
              top: 35%;
              left: 46.5%;
            }
          }
          &#indicater15 {
            .indicater {
              bottom: 50%;
              left: 2.4%;
            }
            .section-detail {
              bottom: 48%;
              left: 2.4%;
            }
          }
        }
      }
    }
    .pictorial-finding-form{
      .pictorial-cards{
        .airframe-cards{
          margin: 0 -10px;
        }
        .add-more-picture{
          margin-bottom:5px;
            img{
              display: inline-block;
              &.delete-icon{
                float: right;
              }
          }
      }
      .note-block{
        font-size: 12px;
        margin-bottom: 10px;
      }
      .slick-arrow{
        background: rgba(0,0,0,.3);
        width: 40px;
        height: 40px;
        &.slick-prev{
          left: -25px !important;
        }
        &.slick-next{
          right: -25px !important;
        }
      }
      .slick-slide{
        .slick-arrow{
          background: rgba(0,0,0,.3);
          border-radius: 50%;
          width: 40px;
          height: 40px;
          padding: 11px 10px;
          &.slick-prev{
            left: 10px !important;
          }
          &.slick-next{
            right: 10px !important;
          }
        }
        >div{
          margin:10px;
        }
        .slider-img-block{
          position: relative;
          img{
            &.action-block{
              cursor: pointer;
              position: absolute;
              top:4px;
            }
            &.delete-icon{
              right:5px;
            }
            &.replace-icon{
              right:27px;
            }
            &.edit-icon{
              right:50px;
            }
            &.finding-img{
              width:100%;
            }
          }
        }
      }
    }
  }
  
}}

