@import '/setting';
@import '/modal';
@import '/mui_override';
@import '/common';
@import '/budget_management';
@import 'tasks_management';
@import 'form14';
@import 't008';
@import 'dashboard.scss';
body{
  .portfolio-fbv-table{
    max-width: 100%;
    max-height: 36em;
    overflow: scroll;
    position: relative;
    table.outer-table{
      position: relative;
      border-collapse: separate;
      border-spacing: 0;
      td,th {
        padding: 0.25em;
        border: 1px solid #d7d7d7;
      }
      thead.outer-head {
        th{
          position: -webkit-sticky; /* for Safari */
          position: sticky;
          top: 0;
          background: #f0f2f3;
          color: #6d9bb9;
          font-size: 13px;
          font-family: $ibmplexMedium;
          &.child-one{left: 0; z-index: 1;}
          &.child-two{left: 140px; z-index: 1}
          &.child-three{left: 280px; z-index: 1}
          &.child-four{left: 420px; z-index: 1}
          &.child-five{left: 560px; z-index: 1}
        }
      }
      tbody{
        th{
          position: -webkit-sticky; /* for Safari */
          position: sticky;
          background: #f0f2f3;
          // border-right: 1px solid #ccc;
          font-size: 13px;
          font-family: $ibmplexMedium;
          &.child-one{left: 0;}
          &.child-two{left: 140px;}
          &.child-three{left: 280px;}
          &.child-four{left: 420px;}
          &.child-five{left: 560px;}
        }
      }
      tbody tr{
        border-bottom: 1px solid #d7d7d7;
        td {
          border: 1px solid #d7d7d7;
          @include elementStyles( $size: 15px, $fontFamily: $ibmplexRegular, $color: #000000 );
        }
      }
    }
  }
  .for-label{
    @include elementStyles($size: 11px, $color: $black, $fontFamily: $ibmplexMedium, $weight:500, $margin:4px 0 0 0)
  }
  .list-graph-sw{
    display: inline-block;
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    span{
      padding: 6px 10px;
      display: inline-block;
      cursor: pointer;
      &:first-child{
        border-right: 1px solid #d7d7d7;
      }
    }
  }
  .projects-management-apps{
    .MuiFormControl-marginNormal{
        margin-bottom: 0;
    }
    .MuiOutlinedInput-input{
        padding: 14px !important;
        @include elementStyles($size: 15px, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
        &.Mui-disabled{
          cursor: not-allowed;
        }
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
        padding: 0;
    }
    .MuiTextField-root {
        label{
            @include elementStyles($size: 14px, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
        }
    }
  }
  .files-upload-limit{
    font-size: 12px;
    // background-color: #e9d273;
  }
  .pictorial-finding-list{
    margin-right: 10px;
    margin-bottom: 10px;
    .pictorial-finding-card{
      height: 200px;
      width: 200px;
      position: relative;
      .dropped-picture-cta{
        background: rgba(255,255,255,0.5);
        position: absolute;
        right: 0;
        top:0;
        padding: 3px;
        z-index: 10;
        cursor: pointer;
        span{
            cursor: pointer;
        }
      }
    }
  }
  .projects-management-section{
    margin-left: 56px;
    margin-top: 15px;
    .project-view-sec{
      .project-view-header{
        margin-bottom: 6px;
        h4{
          @include elementStyles($size: $font15, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
        }
        .project-period, .status{
          @include elementStyles($size: $font11, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
          &.project-period{
            display: inline-block;
            margin-right: 5px;
          }
        }
        ul.project-cta{
          float: right;
          li.list-inline-item{
            margin-left: 10px;

            img{
              width: 26px;
            }
          }
        }
      }
      .form-t012-section{
        margin-bottom: 15px;
        padding: 10px;
        table{
          margin-bottom: 15px;
          td{
            padding: 5px;
            p{
              @include elementStyles($size: $font13, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
            }
            h4{
              @include elementStyles($size: $font15, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
            }
          }
        }
        .form-t012-footer{
          margin-top: 15px;
        }
      }
      .cor-feedback-form{
        .form-label{
          margin-bottom: 10px;
          padding: 8px 15px;
          h4{
            @include elementStyles($size: $font15, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
          }
          .assigned-engineer{
            @include elementStyles($size: $font11, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
            margin-left: 6px;
          }
        }
      }
      .work-order-table{
        p{
          @include elementStyles($size: $font13, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
        }
        h4{
          @include elementStyles($size: $font15, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
        }
        .work-order-asset{
          span{
            @include elementStyles($size: $font11, $color: #3f51b5, $fontFamily: $ibmplexMedium, $weight:500);
            display: inline-block;
            padding: 2px 4px;
            margin-left: 5px;
            cursor: pointer;
            text-decoration: underline;
            svg{
              font-size: $font11;
            }
          }
        }
      }
      .MuiAccordionDetails-root{
        background: #f1f1f1;
        padding: 0 !important;
        .workOrder-forms{
          width: 100%;
          table{
            width: 100%;
            tr{
              border-bottom: 1px solid #d7d7d7;
              &:first-child{
                border-top: 1px solid #d7d7d7;
              }
              td{
                padding: 5px;
                svg{
                  font-size: $font13;
                  position: relative;
                  top: 2px;
                }
                p{
                  @include elementStyles($size: $font11, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
                }
                h4,span{
                  @include elementStyles($size: $font13, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
                }
                span{
                  text-decoration: underline;
                }
                .form-progress{
                  .form-name{
                    margin-left: 5px;
                  }
                }
              }
            }
          }
        }
      }

      .work-order-users{
        clear: both;
        margin-bottom: 15px;
        .user-type{
          // margin-bottom: 10px;
          border-bottom: 1px solid #d7d7d7;
          padding: 5px 10px;
          h4{
            @include elementStyles($size: $font16, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
          }
          button{
            margin-left: auto;
          }
        }
        ul{
          li{
            margin-bottom: 10px;
            padding: 5px 10px;
            .user-details{
              margin-left: 6px;
              h5{
                @include elementStyles($size: $font15, $color: $black, $fontFamily: $ibmplexRegular, $weight:500);
              }
              p{
                @include elementStyles($size: $font13, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
              }
            }

          }
        }
      }
    }

    .projects-list-sec{
      .project-tabs{
        margin-bottom: 15px;
      }
      .project-list-cta{
        float: right;
      }
      .filter-cta{
        padding: 6px;
      }
      table{
        td{
          &:first-child{
            position: relative;
            .project-status-bar{
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: 6px;
              border-radius: 0;
            }
          }
          .project-no{
            @include elementStyles($size: $font13, $color: $black, $fontFamily: $ibmplexRegular);
          }
          .project-name{
            @include elementStyles($size: $font15, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
          }
          .MuiChip-root{
            margin-left: 2px;
            margin-bottom: 2px;
            .MuiChip-label{
              @include elementStyles($size: $font11, $color: $black, $fontFamily: $ibmplexRegular);
            }
          }
        }
      }
    }
  }
  .project-forms-section{
      margin-left: 64px;
      margin-top: 75px;
      .import-form-template{
        padding: 80px 0;
        text-align: center;
        h4{
          @include elementStyles($size: $font22, $color: $black, $fontFamily: $ibmplexRegular, $weight:400, $margin:0 0 15px 0);
        }
        p{
          @include elementStyles($size: $font16, $color: $black, $fontFamily: $ibmplexRegular, $weight:400, $margin:0 0 10px 0);
        }
      }
      .form-wrapper-head{
          padding: 5px 15px 5px 10px;
          background: #fff;
          box-shadow: 0 2px 2px rgba(0,0,0,0.3);
          position: fixed;
          top: 45px;
          left: 70px;
          right: 0;
          z-index: 11;
          .project-detail{
              border-right: 1px solid #d7d7d7;
              padding-right: 10px;
              .project-percentage{
                  // float: left;
                  padding: 0 5px 0 0;
              }
              .project-card{
                  h3{
                      margin-bottom: 3px;
                      span{
                          @include elementStyles($size: 16px, $color: $black, $fontFamily: $ibmplexBold);
                          cursor: pointer;
                      }
                      text-decoration: underline;
                      line-height: 18px;
                  }
                  p{
                      .project-status{
                        margin-right: 5px;
                        display: inline-block;
                          .cancelled{
                            background: #F5C7CA;
                            color:#5e2135;
                            padding: 3px 6px;
                            border-radius: 4px;
                        }
                        .draft{
                            background: #EBD186;
                            color: #7F5E0D;
                            border-radius: 4px;
                            padding: 3px 6px;
                            border-radius: 4px;
                        }
                        .initiated{
                            background: #acacac;
                            color: #000000;
                            padding: 3px 6px;
                            border-radius: 4px;
                        }
                        .launched{
                            background: #7b8bff;
                            color: #fff;
                            padding: 3px 6px;
                            border-radius: 4px;
                        }
                        .in-review{
                            background: #93ec0d;
                            color: #ffffff;
                            padding: 3px 6px;
                            border-radius: 4px;
                        }
                        .completed{
                            background: #93c49f;
                            color: #264D1C;
                            padding: 3px 6px;
                            border-radius: 4px;
                        }
                        .approved{
                            background: #63eac9;
                            color: #264D1C;
                            padding: 3px 6px;
                            border-radius: 4px;
                        }
                        .archived{
                            background: #9a9991;
                            color: #264D1C;
                            padding: 3px 6px;
                            border-radius: 4px;
                        }
                      }
                      .project-dates{
                          @include elementStyles($size: 9px, $color: $black, $fontFamily: $ibmplexMedium);
                          opacity: .9;
                      }
                  }
              }
          }
          .asset-form-card{
            width: 285px;
              h4{
                  @include elementStyles($size: 14px, $color: $black, $fontFamily: $ibmplexMedium);
              }
              p{
                @include elementStyles($size: 13px, $color: $black , $fontFamily: $ibmplexMedium);
            }
              span{
                text-decoration: underline;
                  @include elementStyles($size: 13px, $color: #4050b5 , $fontFamily: $ibmplexMedium);
              }
          }
          .form-action-btn-grp{
              float:right;
          }
      }
      .t001-header{
        margin-top: 3px;
        margin-bottom: 10px;
        .form-right-cta{
          float: right;
          cursor: pointer;
        }
      }
      .t001-general-pictures{
        background: #fff;

        .section-content-blk{
          margin-bottom: 15px;
          padding-bottom: 15px;
          .pictures-row{
            min-height: 200px;
            padding: 5px 10px 10px 10px;
            border-bottom: 1px solid #d7d7d7;
            .section-title{
              .section-info{
                margin-bottom: 10px;
                h3{
                  @include elementStyles($size: 16px, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
                }
                p{
                  @include elementStyles($size: 13px, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
                }
              }
              ul{
                margin-left: auto;
                li{
                  cursor: pointer;
                }
              }
            }
            .picture-ops{
                margin-bottom: 8px;
                .section-name{
                    @include elementStyles($size: 15px, $color: #bd10e0, $fontFamily: $ibmplexRegular);
                    text-decoration: underline;
                }
                ul{
                    margin-left: auto;
                    li{
                        span{
                            @include elementStyles($size: 11px, $fontFamily: $ibmplexRegular);
                        }
                    }
                }
            }
            .dropped-pic-card{
                height: 200px;
                position: relative;
                .dropped-picture-cta{
                    background: rgba(255,255,255,0.5);
                    position: absolute;
                    right: 0;
                    top:0;
                    padding: 3px;
                    z-index: 10;
                    cursor: pointer;
                    span{
                        cursor: pointer;
                    }
                }
            }
          }
        }
      }

      .general-pictures{
          .general-picture-ops{
              margin-bottom: 10px;
              .right-ops{margin-left: auto;}
          }

      }
      .data-plates{
          .section-name{
              @include elementStyles($size: 15px, $color: #bd10e0, $fontFamily: $ibmplexRegular);
              text-decoration: underline;
              margin-bottom: 6px;
          }
          .dropped-pic-card{
              height: 250px;
              position: relative;
              .dropped-picture-cta{
                  padding: 4px;
                  position: absolute;
                  right: 0;
                  top: 0;
                  z-index: 11;
                  background: rgba(255,255,255,0.8);
                  cursor: pointer;
                  span{cursor: pointer;}
              }
          }
      }
      .documents-checklist{
        .no-data{
          text-align: center;
          padding: 10px 0;
          clear: both;
        }
        .checklist-head{
          margin-bottom: 10px;
          ul{
            &.right-cta{
              float: right;
            }
            li{
              margin-right: 10px;
              cursor: pointer;
              &:last-child{
                margin-right: 0;
              }
            }
          }
        }
        .section-list{
          margin-bottom: 10px;
          .section-head{
            display: flex;
            align-items: center;
            padding: 10px 15px;
           // border: 1px solid #d7d7d7;
            width: 100%;
            .section-notes{
              span{
                  @include elementStyles($size: 12px, $color: #000000, $fontFamily: $ibmplexRegular);
              }
              @include elementStyles($size: 10px, $color: #000000, $fontFamily: $ibmplexLight);
              font-style: italic;
          }
            h2{
              @include elementStyles($size: 16px, $color: #000, $fontFamily: $ibmplexRegular);
            }
            ul{
              margin-left: auto;
              li{
                cursor: pointer;
              }
            }
          }
          .sub-section-list{
            
            padding: 10px;
            .section-notes{
              span{
                  @include elementStyles($size: 12px, $color: #000000, $fontFamily: $ibmplexRegular);
              }
              @include elementStyles($size: 10px, $color: #000000, $fontFamily: $ibmplexLight);
              font-style: italic;
          }
            .MuiExpansionPanelSummary-root.Mui-expanded{
              border-bottom: 1px solid #d7d7d7;
            }
            .MuiExpansionPanelSummary-content.Mui-expanded{
              margin: 8px 0 !important;
            }
            .MuiExpansionPanelSummary-root.Mui-expanded{
              min-height: auto !important;
            }
            .sub-section-head{
              display: flex;
              align-items: center;
              width: 100%;
              h4{
                @include elementStyles($size: 15px, $color: #000, $fontFamily: $ibmplexRegular);
              }
              ul{
                margin-left: auto;
              }
            }
            .t003-docs{
              width: 100%;
              .docs-checklist-hd{
                background: #ebebeb;
                p{
                  @include elementStyles($size: 12px, $color: #8c9ba5, $fontFamily: $ibmplexRegular);
                  text-align: left;
                  padding: 5px;
                }
              }
              .docs-checklist-body{
                .t003-document-sorting{
                  border-bottom: 1px solid #d7d7d7;
                  margin: 0;
                }
              }
            }
          }
        }
      }
      .observations-form{
        .observation-table{
          .mui-table-format{
            thead{
              table,td,th,tr{
                background: transparent !important;
                border: none !important;
              }
            }
            tbody{
              .rating-cell{
                table,td,th,tr{
                  background: transparent !important;
                  border: none !important;
                }
              }
            }
          }
        }
        .t004-content-sections{
          .MuiInputBase-multiline{
              padding: 5px !important;
          }
          .MuiFormControl-marginNormal{
              margin-bottom: 0;
          }
          .MuiOutlinedInput-input{
              padding: 14px !important;
              @include elementStyles($size: 15px, $color: $black, $fontFamily: $ibmplexRegular, $weight:400)
          }
          .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
              padding: 0;
          }
          .MuiTextField-root {
              label{
                  @include elementStyles($size: 14px, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
              }
          }
          .content-sections-hd{
            margin: 10px 0;
          }
          .section-title-field{
            input{
              &:focus{
                border: 1px solid #d7d7d7;
              }
              &:hover{
                border: 1px solid #f1f1f1;
                cursor: pointer;
              }
            }
            fieldset{
              border: none !important;
            }

          }
          .MuiAccordionSummary-root{
            &.Mui-expanded{
              border-bottom: 1px solid #d7d7d7;
            }
          }
          .MuiCollapse-wrapper{
            background: #f1f1f1;
          }
          .Mui-focused{
            background: #fff !important;
          }
          .section-content{
            display: block;
            width: 100%;
            .sub-sections-content{
              padding: 10px;
              margin-top: 10px;
              .sub-section-blk{
                border-bottom: 1px solid #d7d7d7;
                padding-bottom: 10px;
                margin-bottom: 10px;
                .sub-section-head{
                  padding: 10px 15px;
                  border-bottom: 1px solid #d7d7d7;
                  h3{
                    @include elementStyles($size: 16px, $color: #000, $fontFamily: $ibmplexMedium);
                  }
                }
                .sub-section-body{
                  padding: 10px 15px;
                }
              }
            }
          }
        }
      }
      .pictorial-findings{
        .fabrik-frame{
          position: relative;
          .new-finding-cta{
            position: absolute;
            top: 10px;
            left: 10px;
            // background: rgba(0,0,0,.45);
            // z-index: 11;
          }
          .frame-position-cta{
            position: absolute;
            background: rgba(0,0,0,.45);
            z-index: 111;
            &.right-position{
              bottom: 55px;
              right: 10px;
              li{
                border-bottom: 1px solid #fff;
                &:last-child{
                  border-bottom: none;
                }

              }
            }
            &.bottom-position{
              bottom: 10px;
              right: 10px;
              li{
                border-left: 1px solid #fff;
                &:last-child{
                  border-bottom: none;
                }
              }
            }
            li{
              color:#fff;
              padding: 10px;
              cursor: pointer;
              &.active{
                background: rgba(0,0,0,0.9);
              }
            }
          }
        }
      }
      .problem-resolution-sheet{
        .prs-status{
          position: absolute;
          left: 0;
          top: 0;
          width: 10px;
          height: 100%;
        }
        .prs-bulk-ops{
          background: #e1e1e7;
          padding:8px 15px;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          z-index: 10;
          ul{
            li{
              cursor: pointer;
              .blk-ops-menu{
                background: #fff;
                padding:5px 10px;
                border-radius: 20px;
                font-size: $font13;
                &.delete-menu{
                  display: inline-block;
                  padding:5px 10px;
                }
              }
            }
          }
        }
      }
      .prs-details-section{
        position: relative;
        .prs-detail-list{
          overflow: auto;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 10;
          width: 220px;
          background: #fff;
          .quick-issue{
            padding: 5px 10px 5px 20px;
            border-bottom: 1px solid #d7d7d7;
            cursor: pointer;
            position: relative;
            &.active{
              background: #c4c4c4;
            }
            &:last-child{
              border-bottom: none;
            }
            h5{
              @include elementStyles($size: $font16, $color: #000000, $fontFamily: $ibmplexMedium, $weight:500);
            }
            .assignee{
              @include elementStyles($size: $font13, $color: #000000, $fontFamily: $ibmplexMedium, $weight:500);
            }
            .status-vertical{
              position: absolute;
              left: 0;
              top: 0;
              width: 10px;
              height: 100%;
            }
          }
        }
        .prs-detail-content{
          margin-left: 230px;
          overflow: auto;
          .issue-title, .issue-description, .issue-other-information{
            margin-bottom: 15px;
            &.issue-title{
             .user-info{
              li{
                .span-label{
                  font-size: 13px;
                }
              }
             }
            }
          }
        }
        .prs-comments-blk{
          .comment{
            padding: 5px 10px;
            border-bottom: 1px solid #d7d7d7;
            clear: both;
            &:last-child{
              border-bottom: none;
            }
            .user-detail{
              margin-bottom: 10px;
              .user-name{
                margin-left: 6px;
                h4{
                  @include elementStyles($size: $font16, $color: #000000, $fontFamily: $ibmplexMedium, $weight:500);
                }
                ul{
                  li{
                    margin-right: 5px;
                    padding-right: 5px;
                    border-right: 1px solid #d7d7d7;
                    cursor: pointer;
                    span{
                      @include elementStyles($size: $font13, $color: #000000, $fontFamily: $ibmplexRegular, $weight:400);
                    }
                    &:last-child{
                      border-right: none;
                    }
                  }
                }
              }
            }
          }
          .add-comment{
            clear: both;
            .comment-box{
              margin-bottom: 10px;
            }
            ul{
              float: right;
              margin: 15px 0px 10px 0px;
              li{
                margin-left: 10px;
              }
            }
          }
        }
      }
      // Engine Forms
      .technical-engine-forms{
        .gap-report-status{
          padding: 5px 0;
          text-align: center;
          @include elementStyles($size: 13px, $color: #000, $fontFamily: $ibmplexRegular);
          display: inline-block;
          width: 100px;
          border-radius: 20px;
          border: 1px solid rgba(0, 0, 0, 0.23);
        }
        .form-detail-card{
          position: relative;
          .scrollable-content{
            padding:1px 15px 15px 15px;
          }
          .edit-details{
            min-height: 29px;
            background: #fff;
            padding: 1px 15px;
            border-bottom: 1px solid #f1f1f1;
            display: flex;
            align-items: center;
            .read-prerequisite{
              @include elementStyles($size: 11px, $color: #3f51b5, $fontFamily: $ibmplexMedium);
              text-decoration: underline;
              cursor: pointer;
            }
            .edit-g{
              margin-left: auto;
            }
          }

          .content-editor{
            margin-top: 5px;
            label{
              @include elementStyles($size: 11px, $color: #000, $fontFamily: $ibmplexMedium);
            }
          }
          .actions-cta{
            background: #fff;
            padding: 4px 15px;
            border-top: 1px solid #f1f1f1;
          }
        }
        .forms-table-layout{
          .forms-table-cta{
            padding: 5px;
            ul{
              float: right;
            }
          }
        }
        .te001-inspection-pictures{
          padding: 15px;
          .pictures-blk{
            margin-top: 10px;
          }
        }
        .image-sorting-cn{
          ul.list-inline{
            display: inline !important;
          }
        }
        .gen-detail-title{
          @include elementStyles($size: 16px, $color: #691ad3, $fontFamily: $ibmplexRegular);
          margin-bottom: 10px;
          .sv-list-link{
            font-size: $font13;
            text-decoration: underline;
            float: right;
          }
          span{
            color: #2f8cff;
            font-size: $font13;
            cursor: pointer;
          }
        }
        .inspection-picture-list{
          li{
            margin-right: 20px;
            margin-bottom: 10px;
          }
        }
        .accessories-list-table{
          table{
            th, td{
              table{
                thead, tr, th{
                  background: transparent !important;
                }
              }
            }
          }
        }
        .engine-te004-form{
          margin-bottom: 30px;
          h3.title{
            @include elementStyles($size: 16px, $color: #691ad3, $fontFamily: $ibmplexRegular, $margin: 0 0 10px 0);
          }
          p.para{
            @include elementStyles($size: 12px, $color: #000000, $fontFamily: $ibmplexRegular, $margin: 0 0 4px 0);
          }
        }
        .engine-te005-form{
          .disassembly-findings{
            padding:10px 15px;
            .disassembly-finding-content{
              margin-bottom: 10px;
              border: 1px solid #d7d7d7;
              .disassembly-finding-header{
                padding: 5px 15px;
                background: #f1f1f1;
                h4{
                  @include elementStyles($size: 15px, $color: #000, $fontFamily: $ibmplexMedium);
                }
                p{
                  @include elementStyles($size: 13px, $color: #000, $fontFamily: $ibmplexRegular);
                }
                ul{
                  float: right;
                }
              }
              .disassembly-finding-body{
                padding: 5px 15px;
              }
            }
          }
        }
        .engine-te006-sections{
          .sections-content{
            margin-bottom: 15px;
            .section-header{
              padding: 5px 10px;
              background: #f1f1f1;
              h4{
                @include elementStyles($size: 15px, $color: #000, $fontFamily: $ibmplexRegular);
              }
              ul{
                float: right;
              }
            }
            .section-body{
              padding: 5px 10px;
            }
          }
        }
      }
      .engine-workscope-module-table{
        .border-right{
          border-right: 1px solid #dee2e6;
        }
        .border-left{
          border-left: 1px solid #dee2e6;
        }
        .workscope-table-heading{
          background: #fafafa;
          border: 1px solid #dfe3e6;
          margin: 0;
          overflow: visible;
          h6{
            padding: 6px 6px;
            @include elementStyles($size: 13px, $color: #6d9bb9, $fontFamily: $ibmplexMedium);
          }
          .workscope-level{
            h6{
              border-bottom: 1px solid #dfe3e6;
              text-align: center;
            }
          }
        }
        .engine-workscope-table{
          background: #fff;
          .add-new-row{
            @include elementStyles($size: 12px, $color: #3f51b5, $fontFamily: $ibmplexRegular);
            text-decoration: underline;
            cursor: pointer;
          }
          .del-row{
            margin: 0 auto;
            cursor: pointer;
          }
          .workscope-row{
            border-bottom: 1px solid #d7d7d7;
            &:nth-child(even){
              background: #f5f7fa;
            }
            &:nth-child(odd){
              background: #ebeff6;
            }
            .module-col{
              position: relative;
              .module-content{
                padding: 15px;
              }
            }
            .workscope-sub-row{
              border-bottom: 1px solid #d7d7d7;
              &:last-child{
                border-bottom: none;
              }
              .submodule-col{
                .submodule-content{
                  padding: 15px;
                }
              }
              .workscope-item-row{
                border-bottom: 1px solid #d7d7d7;

                .item-col{
                  .item-content{
                    width: 100%;
                    padding: 15px;
                  }
                  &.workscope-level{

                  }
                }

              }
            }
          }
        }
      }
  }
  .image-custom-preview{
    .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 66vh !important;
  }
  .image-gallery-content.fullscreen{
    padding-top: 100px;
  }
}

// Form T015 css start
.form-t015{
  h5{
    font-family: 'Conv_IBMPlexSans-Medium';
    font-weight: 500;
    margin-bottom: 5px;
    color: #bd10e0;
  }
  .span-style{
    	margin:0px 5px
  }
  .ref-llp-status-date{
   	 	.MuiFormControl-marginNormal{
     		 margin: 0px;
   	 	}
  }
  
  .engine-stand-select{
    width: 155px;
  }
  .mui-table-format-t015{
	margin:10px 0px 5px;
	.table-align-head{
		   .MuiTableCell-root{
				 text-align: center;
			}
	   }
		.table-align-body{
			tr{
				&:hover{
				  background: #eff0f5 !important;
				}
				&:nth-child(odd){
				  background: #fff;
				}
				&:nth-child(even){
				  background: #E6EBFB;
				}
			  }
			.MuiTableCell-root{
				 text-align-last: center;
			}
		  }
    	h6{
     		font-family: 'Conv_IBMPlexSans-Medium';
      		font-weight: 500;
    	}
		.MuiTableCell-root{
			// border:1px solid #b1b0b094;
      border:1px solid #71717194;
      
			padding:6px
		}
    	.MuiFormControl-marginNormal{
      		margin-top: 0px;
      		margin-bottom: 0px;
    	}
      	thead{
        table,td,th,tr{
            background: #b5bedb;
          }
       }
   
  }
  		.MuiAutocomplete-inputRoot{
		 padding: 0px;
	    }
		.MuiOutlinedInput-input {
			padding: 10px 14px;
	    }
		.MuiOutlinedInput-notchedOutline {
			border-color: #4f4d4d3b;
	   }
     .MuiOutlinedInput-input.Mui-disabled {
      cursor: not-allowed;
      background: #f3f3f3;   
     }

}
}
