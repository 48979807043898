@import '/setting';

body {
    .template-mui-root {
        padding: 15px !important;
        margin-top: 1px !important;
        overflow: auto !important;

        .MuiInputBase-multiline {
            padding: 5px !important;
        }

        .MuiFormControl-marginNormal {
            margin-bottom: 0;
        }

        .MuiOutlinedInput-input {
            padding: 14px !important;
            @include elementStyles($size: 15px, $color: $black, $fontFamily: $ibmplexRegular, $weight: 400);

            &.Mui-disabled {
                cursor: not-allowed;
            }
        }

        .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
            padding: 0;

            &.Mui-disabled {
                cursor: not-allowed;
            }
        }

        .MuiTextField-root {
            label {
                @include elementStyles($size: 14px, $color: $black, $fontFamily: $ibmplexMedium, $weight: 500);
            }
        }

        .MuiDialogTitle-root {
            padding: 10px 24px;

            h2 {
                @include elementStyles($size: 16px, $color: $black, $fontFamily: $ibmplexMedium, $weight: 500);
            }
        }

        .MuiDialogContent-root {
            padding: 10px 24px;
        }

        .MuiDialogActions-root {
            padding: 10px 24px;
        }

        .MuiGrid-spacing-xs-3>.MuiGrid-item {
            padding: 8px 12px 4px 12px;
        }
    }
    .cursor-pointer{
        cursor: pointer;
    }
    .cursor-not-allowed{
        cursor: not-allowed !important;
    }

    .truncate {
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .paper-border-bottom {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px
    }

    .paper-border-top {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px
    }

    .vertical-line {
        border-left: 2px solid #d0cdcd;
        height: 40px;


    }

    .outer-line-margin {
        margin-top: 30px;
    }

    ;

    .inner-line-margin {
        margin-top: 18px;
    }

    .sort-picture-item {
        &.inspection-picture {
            .dropped-pic-card {
                border: 1px solid #d7d7d7;
                border-bottom: none;
            }

            .MuiOutlinedInput-root {
                border-radius: 0;
            }
        }

        .dropped-pic-card {
            height: 200px;
            position: relative;

            .dropped-picture-cta {
                background: rgba(255, 255, 255, 0.5);
                position: absolute;
                right: 0;
                top: 0;
                padding: 3px;
                z-index: 10;
                cursor: pointer;

                span {
                    cursor: pointer;
                }
            }
        }
    }

    .attachment-text-div {
        border: 1px groove lightgray;
        border-radius: 6px;
        margin-top: 8px;
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 232px
    }

    .attachment-img {
        width: 208px;
        height: 208px;
        z-index: 9;
        border: groove 1px;
        border-radius: 4px;
        border-color: lightgray;
        margin-top: 10px;
        padding: 2px;
    }

    .attachment-link {
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        color: rgba(6, 6, 6, 0.63);
    }
    .fitted-label{
        font-size: 14px;
         margin-left: -8px;
        font-weight : 500;
        color:  #000000;
        font-family: "Conv_IBMPlexSans-Medium"
    }

    .section-title{
        padding-bottom: 2px;
        font-size: 16px;
        color: #691AD3;
        display: flex;
        justify-content: space-between
    }
    .text-area{
        border: 1px solid rgb(229, 227, 227);
        margin-top: 4px;
        width: 100%;
        padding: 8px;
        font-size: 16px;
        height: 90px !important;
        overflow: auto !important;
    }
    
    
}