
@import '../../../assets/styles/setting';
.sub-headder-info{
  .sub-headder-fixed{
    position: fixed;
    left: 70px;
    right: 0;
    top: 40px;
    width: 100%;
    z-index: 99;
    background-color:$color_fff;
    -webkit-box-shadow: 0 4px 4px -4px rgba(0,0,0,.61);
    -moz-box-shadow: 0 4px 4px -4px rgba(0,0,0,.61);
    box-shadow: 0 4px 4px -4px rgba(0,0,0,.61);
    margin: 0;
    padding: 10px 0;
    .switcher-container{
      padding-left: 6px;
      .left-side-info{
        .para-ui{
          font-family: $ibmplexRegular;
          font-size: 16px;
          color: #bd10e0;
        }
        ul.msn-switcher{
          li{
            margin-right: 16px;
            .switcher-msn{
              color: #000000!important;
              font-family: Conv_IBMPlexSans-Regular;
              font-weight: bolder;
            }
            .custom-select-block{
              width: 201px;
              .Select-arrow{
                border-color:#007bff transparent transparent;
              }
              .Select-control{
                height: 30px;
                background: #FCFEFF;
                border: none;
                border-radius: 4px;
                box-shadow: 0 1px 2px rgba(0,0,0,0.4);
                .Select-placeholder{
                  padding-left: 5px;
                }
                .Select-value-label{
                  padding-left: 5px;
                  .switcher-msn{
                    color: #000000!important;
                    font-family: $ibmplexBold;
                    font-weight: bolder;
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
        ul.other-links{
          li{
            a{
              img{
                margin-right: 6px;
              }
              font-size: 14px;
            }
          }
          .inventory-link{
            position: relative;
            color: #2F8CFF;
            font-size: 12px;
            left: 10px;
            img{
              width: 15px;
              margin-right: 5px;
              margin-bottom: 3px;
            }
            &:hover{
              color: #0056b3;
            }
          }

        }
      }
    }

    .contract-lease-info{
      ul{
        position: absolute;
        right: 100px;
        li{
          margin-right: 25px;
          &:last-child{
            margin-right: 0px;
          }
          h6{
            font-family: $ibmplexRegular;
            font-size: 12px;
            color: #a0a0a0;
            margin-bottom: 2px;
          }
          span{
            font-family: $ibmplexRegular;
            font-size: 14px;
            color: #000;
          }
        }
      }
    }
    .contract-draft-mode{
      position: fixed;
      width: 100%;
      top: 93px;
      z-index: 0;
      background: #fff3cd;
      text-align: center;
      color: #856404;
      padding: 2px 0px;
      font-size: 14px;
      font-family: $ibmplexRegular;
      border:1px solid #d4bb73;
      span{
        font-family: $ibmplexSemiBold;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

header{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #ffffff;
  z-index: 999;
  padding: 0 20px 0 3px;
   border-bottom: 1px solid #ddd;
  .Select{
    .Select-clear{
      display: none;
    }
  }
  &.logedout-header{
    margin-bottom:36px;
  }
  // &:focus, &:active{
  //   box-shadow: 0 1px 2px rgba(0,0,0,0.3);
  //  }
  .spacing{
    padding: 0 5px;
  }
  .access-block{
    justify-content: flex-end;
    .cart-count{
      color: #fff;
      font-size: 8px;
      display: inline-block;
      position: relative;
      top: -10px;
      background: red;
      border-radius: 50%;
      padding-left: 3px;
      padding-right: 3px;
      margin-left: -10px;
    }
  }
  .header-logo-search{
    &.first-block{
      // width: 65px;
      float: left;
      display: block;
    }
    &.second-block{
      float: left;
      width: 75%;
    }
    li{
      &:first-child{
        padding:0 18px 0 0px;
      }
      h5{
        font-family: $ibmplexMedium;
        font-size: 16px;
        color: #bd10e0;
        cursor: pointer;
        text-transform: uppercase;
      }
    }
    .global-search{
      // margin-left: 128px;
      width: 100%;
      form{
        position: relative;
        ul.search-items{
          position: absolute;
          left: 10px;
          top: 3px;
          li{
            position: relative;
            background: #e5effb;
            padding: 2px 20px 2px 0px;
            border-radius: 3px;
            font-size: 14px;
            margin-left: 6px;
            margin-right: 0;
            border: 1px solid #fff;
            box-shadow: 0 1px 2px rgba(0,0,0,.22);
            font-family:$ibmplexRegular;
            &:first-child{
              margin-left: 0;
            }
            img{
              padding: 5px;
            }
            span{
              position: absolute;
              right: 5px;
              top: 2px;
              padding: 0;
            }
          }
        }
        span{
          position: absolute;
          right: 0;
          top: 3px;
          height: 100%;
          padding: 4px 10px;
          cursor: pointer;
          img{
            width: 18px;
          }
        }
        input[type="text"]{
          padding-left: 10px;
          border: 1px solid #ccc;
          width: 100%;
          height: 40px;
          border: 0;
          border-left: 1px solid #ddd;
          border-right: 1px solid #ddd;
          background: #F3F6F9;
        }
      }

    }
    &.header-action-items{
      margin:7px 7px 0px 0px;
      li{
        margin-right:5px;
        padding:10px;
      }
    }

  }
  

  .header-user-info{
    border-left: 1px solid #ddd;
    .help-icon{
      cursor: pointer;
      font-size: 12px;
      font-family:$ibmplexRegular;
      color: #a1a1a1;
      margin-right: 15px;
      margin-left: 15px;
    }
    .lessor-info{
      width: 160px;
      border-right: 1px solid #ddd;
      padding-right: 20px;
      margin:0;
      margin-left: 10px;
      .lessor-name{
        width: 160px;
        padding-right: 20px;
        margin: 0;
        color: #000;
        font-size: 14px;
      }
      img{
        height: 35px;
        object-fit: contain;
        width: 92%;
      }
      span{
        font-size: 12px;
        font-family:$ibmplexRegular;
        color: #3f51b5;
        cursor: pointer;
      }
    }
    .lessor-label{
      font-size: 10px;
      font-family:$ibmplexRegular;
      color: #a0a0a0;
    }
    .Select{
      width: 128px;
    }
    .dropdown-menu{
      top: 7px !important;
      padding: 0;
      button{
        color: #000;
        font-family:$ibmplexRegular;
        font-size: 14px;
        cursor: pointer;
        border-bottom: 1px solid #dfe3e6;
        padding: 10px 12px;
        &:hover{
          color: #fff;
          background-color: #3f51b5;
        }
        a{
          color: #000;
        }
      }
    }
    .show>.btn-secondary.dropdown-toggle{
      background: transparent;
      border: none;
    }
    .btn{
      padding: 0;
    }
    .dropdown-toggle{
      &:after{
        position: absolute;
        right: -13px;
        top: 20px;
        color: #3f51b5;
      }
    }
    .btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus{
      box-shadow: none;
    }
    btn-secondary.focus, .btn-secondary:focus{
      border: none;
      box-shadow: none;
    }
    .btn-secondary, .dropdown-toggle{
      background: transparent;
      border: none;
      box-shadow: none;
    }
    img.profile-pic{
      width: 35px;
      height: 35px;
      border-radius: 50%;
      float: left;
      margin-right:8px;
    }
    .user-blk{
      h4{
        font-size: 14px;
        color: #000;
        font-family:$ibmplexRegular;
        text-align: left;
      }
      p{
        font-size: 12px;
        color: #000;
        font-family:$ibmplexRegular;
        text-align: left;
      }
    }

    .login-logout-link{
      a{
        font-size: 14px;
        font-family: $ibmplexRegular;
        padding:0 15px;
      }
    }
    .user-detail-block{
      width: 236px;
      padding: 0 18px;
      position: relative;
      .profile-pic{
        position: absolute;
        left: 10px;
        top: 5px;
      }
     .dropdown{
       width: 97%;
        >button{
          width: 100%;
          cursor: pointer;
          &:active,&:focus{
            background: transparent;
            background-color: transparent;
          }
          .user-blk{
            padding: 5px 5px 5px 35px;
            >div{
              position : relative;
              top: 3px;
            }
          }
        }
     }
    }
  }
}

.generate-finance-heading{
  margin-right: -15px;
  background-color:$color_fff;
  padding: 20px ;
  -webkit-box-shadow: 0px 10px 20px -11px rgba(0,0,0,0.61);
  -moz-box-shadow: 0px 10px 20px -11px rgba(0,0,0,0.61);
  box-shadow: 0px 10px 20px -11px rgba(0,0,0,0.61);
  overflow: hidden;
  margin-bottom: 30px;
  &.contract-fixed-header{
    position: fixed;
    top: 50px;
    right: 0;
    left: 255px;
    z-index: 9;
    padding: 20px 20px 15px 0;

  }
  .contract-draft-mode{
    width: 100%;
    top: 0;
    z-index: 99;
    background: #fff3cd;
    left: 15px;
    text-align: center;
    color: #856404;
    padding: 2px 0px;
    font-size: 14px;
    font-family: $ibmplexRegular;
    border:1px solid #d4bb73;

    span{
      font-family: $ibmplexSemiBold;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &:focus, &:active{
    -webkit-box-shadow: 0px 0px 20px -4px rgba(0,0,0,0.61);
    -moz-box-shadow: 0px 0px 20px -4px rgba(0,0,0,0.61);
    box-shadow: 0px 0px 20px -4px rgba(0,0,0,0.61);
  }
  .tech-spec-link{
    margin-top:3px;
    font-size: 12px;
    display: block;
    color:#368FFC;
    transition: all 0.5s ease;
    img{
        margin-right:4px;
    }
  }
  h5{
    font-family:$ibmplexRegular;
    font-size:16px;
    color:#bd10e0;
    margin-bottom: 5px;
    &.export-pdf-heading{
      cursor: pointer;
      .btn-group{
        float:right;
        button{
          background: transparent;
          border:0;
          box-shadow:0 0 0;
          span{
            color:#368FFC;
          }
          &::after{
            color:#368FFC !important;
          }
        }
        *:focus{
          box-shadow:0 0 0;
        }
      }
      .dropdown-menu{
        cursor: pointer;
        padding:0;
        background: #fff;
        border: 1px solid #e9e9e9;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
        border-radius: 3px;
        padding: 10px;
        img{
          width:12px;
          margin-right:6px;
        }
        button{
          padding:0;
          font-family: Conv_IBMPlexSans-Regular;
          font-size: 12px;
          color: #2f8cff;
        }
      }
    }
  }
  h2{
    float:left;
    font-family:$ibmplexLight;
    font-size:24px;
    color:#000000;
    text-align:left;
      &.gengraph-text{
        margin-bottom:20px;
        span{
          font-size: 14px;
          color: #2F8CFF;
          display: inline-block;
          margin-left: 20px;
          cursor: pointer;
        }
        .cashflow-link{
          font-family:$ibmplexRegular;
          color: #2670CA;
          font-size: 14px;
          border: 1px solid #2670CA;
          padding: 3px 10px;
          border-radius: 3px;
          cursor: pointer;



        }
      }

      &.gen-finance-text{
        margin-bottom: 7px;
      }
      a{
        color: #000;
      }
      .tab-right{
        padding-left: 10px;
        background: transparent;
        float: right;
        position: relative;
        top: -13px;
        li{
          &.count-block{
            background:#2f8cff;
            border-radius:20px;
            line-height: 12px;
            padding: 1px 5px;
            span{
              font-family:$ibmplexRegular;
              font-size:8px;
              color:$color_fff;
              position: relative;
              top:-1px;
              display: inline-block;
            }
          }
        }
      }
      .asset-status{
         margin-left:10px;
         text-transform: uppercase;
         font-family: $ibmplexSemiBold;
         border-radius: 4px;
         font-size: 10px;
         padding: 4px 0 2px;
         display: inline-block;
         width: 80px;
         text-align: center;
         line-height: 12px;
         position: relative;
         bottom:5px;
      }
      img{
        margin-right:6px;
      }
      .ser-reg-number{
        font-size: 14px;
        margin-left: 4px;
        font-family: $ibmplexLight;
        color: #000 !important;
      }
      .review-btn{
        cursor: pointer;
        float: right;
        padding: 8px 26px;
        color:$color_fff;
        &:hover{
          color:$color_fff;
        }
      }
    }
    .lessor-select-block{
      margin-bottom:30px;
    }
    h6{
      font-family:$ibmplexRegular;
      font-size:12px;
      color: #a0a0a0;
      margin-bottom: 2px;
    }
    ul{
      &.generate-select-boxes{
        float: right;
        li{
          margin-right: 25px !important;
          &:last-child{
            margin-right:0  !important;
          }
          span{
            font-size: 14px;
          }
          label{
            cursor: pointer;
            font-family:$ibmplexRegular;
            font-size:14px;
            color: #a0a0a0;
            line-height:12px;
            span{
              margin-left: 10px;
            }
          }
        }
      }
    }
    .review-wrapper-block{
      ul{
        li{
          span{
            font-family:$ibmplexRegular;
            font-size: 12px;
            color:#a0a0a0;
            text-align: left;
          }
          p{
            font-family:$ibmplexRegular;
            font-size:14px;
            color:#000000;
            line-height:14px;
          }
        }
      }
    }
  .finance-graph-widget{
    h5{
      font-size: 12px;
      margin-bottom: 8px;
      font-family: $ibmplexRegular;
      color: #a0a0a0;
      margin-top: 25px;
      margin-bottom: 8px;
      .date-filter{
        display: inline-block;
        margin-left: 10px;
        input{
          border-bottom: 2px solid #e1e1e1;
          padding-left: 5px;
        }
      }
    }
    ul{
      li{
        border-radius: 3px;
        padding: 15px 20px;
        margin-right: 5px;
        margin-bottom: 5px;
        span{
          font-family:$ibmplexRegular;
          color:$color_fff;
          font-size: 12px;
          opacity: 0.3;
        }
        h6{
          font-family:$ibmplexSemiBold;
          color:$color_fff;
          span{
            font-size: 18px;
            opacity: 1;
          }
        }
        &.total-funds{
          background: #553ED2;
        }
        &.engines-funds{
          background: #644EDF;
          .col{
            min-width: 100px;
          }
        }
        &.lg-funds{
          background: #4E79DF;
        }
        &.apu-funds{
          background: #4E90DF
        }
        &.airframe-funds{
          background: #37B7E9;
          .col{
            min-width: 100px;
          }
        }
      }
    }
  }
}



@media (max-width: 575.98px) {
  .generate-header-outer-wrap{
    img{
      &.ml-upload-img{
        width: 320px;

      }
    }
    .drag-drop-block{
      p{
        a{
          display: block;
          width: 122px;
          margin: 6px auto 0 !important;
        }
      }
    }
    .mluploaded-file{
      margin:0;
      .upload-row{
        >div{
          margin-bottom:15px;
          &.text-right{
            text-align: left !important;
          }
        }
      }
    }

  }

  .subheader-height{
    display:none;
  }


}


// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .generate-header-outer-wrap{
    margin-left: 224px;
    margin-right: 10px;

  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .generate-header-outer-wrap{
    margin-left: 224px;
    margin-right: 10px;
  }
}
.editSideBar{
  position: fixed;
  background: #fff;
  z-index: 1000;
  box-shadow:0 2px 4px 0 rgba(0,0,0,0.50);
  height: 100vh;
  top:0;
  transition:all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  width:470px;
  right: 0px;
  h3{
    // color: #9439e4;
    // font-size: 18px;
    // padding: 10px 20px;

    margin: 28px 15px 5px 25px;
    position: relative;
    font-family: Conv_IBMPlexSans-Regular;
    font-size: 16px;
    color: #9439e4;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 11px;
    img{
        position: absolute;
        right: 6px;
        top: 7px;
        cursor: pointer;
    }

  }
  .editSideBar-footer{
    padding: 15px 22px;
    background: #f0f2f3;
    position: absolute;
    bottom: 0;
    width: 100%;
    .save-continue{
      background: #fff;
      color: #0077ff!important;
    }
    .btn-primary{
        cursor: pointer;
        font-family: $ibmplexRegular;
        font-size: 12px;
        color: #fff;
        padding: 6px 25px;
        margin-right: 30px;
    }


    .btn-default{
        cursor: pointer;
        font-family:$ibmplexRegular;
        font-size: 12px;
        color: #2f8cff;
        background: transparent;
        padding:0;
    }
  }
}
.application-landing-page{
  .MuiTabs-scroller{
    margin-bottom: 0px;
  }
  h3{
      @include elementStyles($size: $font16, $color:#5666f9, $fontFamily: $ibmplexMedium, $weight:500, $margin:0 0 6px 0);
  }
  .application-card{
      padding: 20px;
      background: #7d7561;
      box-shadow: 0 2px 2px rgba(0,0,0,.25);
      border-radius: 4px;
      color: #fff;
      min-height: 225px;
      position: relative;
      &.no-access-app{
          opacity: 0.85;
      }
      cursor: pointer;
      .lock-icon{
          position: absolute;
          top: 10px;
          right: 20px;
          z-index: 10;
      }
      .app-title{
          margin-bottom: 15px;
          img{
              width: 50px;
              margin-right: 15px;
          }    
          h4{
              @include elementStyles($size:18px, $fontFamily: $ibmplexMedium, $weight:500);
              sup{
                  color: #ab0202;
              }
          }
      }
      .app-desc{
          p{
              @include elementStyles($size:16px, $fontFamily: $ibmplexRegular, $weight:400);
          }
      }
      button{
          position: absolute;
          bottom: 10px;
          left: 20px;
          z-index: 20;
          color: #fff
      };
      .no-access-content{
          position: absolute;
          bottom: 10px;
          left: 20px;
          z-index: 20;
          color: #fff03b;
          @include elementStyles($size:13px, $fontFamily: $ibmplexRegular, $weight:400);
      }
  }   
  }
